import React, { useState } from "react";
import {Modal, Button} from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/custom.css";
import Footer from "../Components/Footer";

const SubmitCollectible = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className="main-container st-submit">
            <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <div className="stormaLight radius-15 p-5 mb-5">
                        <div className="">
                            <p className="font-17 mb-5">Submit Your Work As An NFT for Storma</p>
                            <div className="row mb-4">
                                <div className="col-md-6">
                                    <div className="">
                                        <input type="text" className="form-control h-45 font-14 radius-6 bg-theme border-0"
                                               placeholder="Name"/></div>
                                </div>
                                <div className="col-md-6">
                                    <div className="">
                                        <input type="text" className="form-control h-45 font-14 radius-6 bg-theme border-0"
                                               placeholder="Email"/></div>
                                </div>

                            </div>
                            <p className="font-17 mb-4">Description of NFT's<br/>
                                Be sure to Provide a small paragraph description for each
                            </p>
                        <div className="mb-4">
                            <textarea className="form-control font-14 radius-6 bg-theme border-0" style={{height: "300px"}}
                                      placeholder="Type here..."></textarea></div>
                            <p className="font-17">Image, Video, Audio, or 3D Model *<br/>
                                File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF. Max size: 70 MB</p>

                            <div className="custom-file">
                                <input type="file" className="custom-file-input" id="customFile"/>
                                    <label className="custom-file-label radius-12" htmlFor="customFile"></label>
                            </div>
                            <p className="font-17 mt-3">Drop files here</p>
                            <div className="d-flex">
                                <a className="btn d-flex align-items-center text-light font-15 ml-auto">Submit <span className="ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                                           width="14.25" height="10.5"
                                                                                                                                           viewBox="0 0 14.25 10.5"><path
                                    id="Vector"
                                    d="M10.153,10.271l3.44-3.44a2.255,2.255,0,0,0,0-3.182L10.153.211a.75.75,0,0,0-1.06,1.06l3.219,3.22H.75a.75.75,0,0,0,0,1.5H12.313l-3.22,3.22a.75.75,0,1,0,1.06,1.061Z"
                                    fill="#fff"></path></svg></span></a>
                            </div>

                        </div>
                    </div>
                    <div className="stormaLight radius-15 p-5 mb-5">
                        <div className="wlist">
                            <ul>
                                <li className="font-15"><div className="font-18 mb-2">What is Storma</div>
                                   <p>Storma is a platform that empowers creators to better protect and sell their digital work to a global audience. Artists, photographers, writers, and more use Storma to create and sell their work online through the use of blockchain technology.</p>
                                </li>
                                <li className="font-15"><div className="font-18 mb-2">What am I selling?</div>
                                    <p>You're selling a signed and limited edition copy of your digital creation to be owned.
                                    Upon purchase, the buyer will be given the right to use, distribute and display the
                                    creation for non-commercial purposes only. Since the buyer owns this unique copy,
                                    they can also re-sell the creation on a secondary market or even directly on
                                    Storma.<br/>
                                    Similar to a physical creation, the buyer will be able to do what they'd normally be able
                                    to do once they purchase a physical piece. This capability is what makes your digital
                                        creations more valuable.</p>
                                </li>
                                <li className="font-15"><div className="font-18 mb-2">Who owns my creations?</div>
                                    <p>Storma is a platform for creators and has no rights over your creations. We are
                                    committed to ensuring that any digital creation published through Storma is
                                        owned by you (the creator). You can find more details in our terms of service.</p>
                                </li>
                                <li className="font-15"><div className="font-18 mb-2">If I sell using Storma, can I sell the same creations elsewhere?</div>
                                    <p>You own the rights to your work, so you can sell your creations elsewhere. However,
                                    once you've tokenized your creation as a limited edition, it's important that you do
                                    not issue more tokens of the same creations which may negatively affect the value
                                        of your creation.</p>
                                </li>
                                <li className="font-15"><div className="font-18 mb-2">How do I receive payment?</div>
                                    <p>Depending on what form of payment was used to purchase your digital creation,
                                        you'll get paid accordingly.
                                    </p>
                                    <p>Credit Card Purchases. We support PayPal (a popular global payment solution) to
                                        issue payments for creations purchased using a credit card.
                                    </p>
                                    <p>PayPal. With PayPal, payments will be deposited to your PayPal account within two
                                        weeks of sale. Once the payment has cleared through PayPal, the funds will be
                                        immediately available to you.
                                    </p>
                                    <p>Ether Purchases. For purchases made through Ether, we will issue payments to you
                                        either directly to your digital wallet (e.g MetaMask) or to your Coinbase wallet.
                                    </p>
                                    <p>You can view all purchases and payments issued to you by going to your Sale History
                                        in the store manager. You can set your preferred payment options by going to your
                                        Store Settings page in the store manager.</p>
                                </li>
                                <li className="font-15"><div className="font-18 mb-2">How do I get started?</div>
                                    <p>Storma is currently invite-only, however we'd love for you to join us. Please reach out
                                        to us in our Discord community for an invite code.</p>
                                </li>
                            </ul>

                        </div>

                    </div>
                </div>
            </div>
            </div>
            <Footer />
        </div>
    )
}


export default SubmitCollectible;
