import React, { useState } from "react";
import {Modal, Button} from "react-bootstrap";
import Footer from "../Components/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/custom.css";

const ConnectWallet = () => {

    return (
            <div className="main-container bottom-light st-connect">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <div className="stormaLight radius-15 p-5 mb-5">

                                <h2 className="font-35 font-w400 text-info mb-3">Thank You!</h2>
                                <p className="font-15">Thank you for your interest in Storma, the premier rare digital collectibles marketplace.</p>
                                <p className="font-15">Please fill out the following application to join. Storma is currently invite-only for artists, so your acceptance is not guaranteed.</p>
                                <p className="font-15">Note: If you do not hear back within 3 weeks, it means that your invite wasn’t approved at this time.  This could be due to a number of reasons as a result of the high volume of requests we receive.</p>
                                <p className="font-15">Thank you for your interest!</p>
                                <p className="text-info font-15 mb-0">*Required</p>
                            </div>
                            <div className="stormaLight radius-15 p-5 mb-5">
<div className="row">
    <div className="col-md-6">
        <div className="mb-4">
            <input type="text" className="form-control h-45 font-14 radius-6 bg-theme border-0"
                   placeholder="First Name*"/></div>
    </div>
    <div className="col-md-6">
        <div className="mb-4">
            <input type="text" className="form-control h-45 font-14 radius-6 bg-theme border-0"
                   placeholder="Last Name*"/></div>
    </div>
    <div className="col-md-6">
        <div className="mb-4">
            <input type="text" className="form-control h-45 font-14 radius-6 bg-theme border-0"
                   placeholder="Email Address*"/></div>
    </div>
    <div className="col-md-6">
        <div className="mb-4">
            <input type="text" className="form-control h-45 font-14 radius-6 bg-theme border-0"
                   placeholder="Phone Number"/></div>
    </div>
</div>

                                <p className="font-15">Please provide full URL social media links and references *</p>
                                <p className="font-15">As we like to ensure the protection of artists' creations, identity verification is an important aspect of the on-boarding process. Please temporarily add your email (the same one you provided above) to your Website / Portfolio / Social Media bio, to verify your ownership of them. List the link(s) where we can verify the placement of your email, below:</p>
                                <div className="mb-4">
                                    <input type="text" className="form-control h-45 font-14 radius-6 bg-theme border-0"
                                           placeholder="Type Here..."/></div>
                                <p className="font-15">Please provide a link to Google Drive or Dropbox with examples of your best, past work. *</p>
                                <p className="font-15">Please provide links that showcase examples of your past work.</p>
                                <div className="mb-4">
                                    <input type="text" className="form-control h-45 font-14 radius-6 bg-theme border-0"
                                           placeholder="Insert URL here..."/></div>
                                <p className="font-15">Please provide background information on yourself (achievements, awards, etc.) and related to your art career. *</p>
                                <div className="mb-4">
                                    <input type="text" className="form-control h-45 font-14 radius-6 bg-theme border-0"
                                           placeholder="Type Here..."/></div>
                                <p className="font-15">If accepted, will you be using your own digital wallet?</p>

                                <div className="form-check mb-3">
                                    <input className="form-check-input" type="radio" name="exampleRadios"
                                           id="yes" value="option1" checked>
                                    </input>
                                    <label className="form-check-label font-14" htmlFor="yes">
                                        Yes
                                    </label>
                                </div>
                                <div className="form-check mb-4">
                                    <input className="form-check-input" type="radio" name="exampleRadios"
                                           id="none" value="option1" checked>
                                    </input>
                                    <label className="form-check-label font-14" htmlFor="none">
                                        No
                                    </label>
                                </div>

                                    <p className="font-15">By using our website, you agree to our Terms of Use and Privacy Policy. You also agree to receive periodic newsletters from us.</p>
                           <div className="d-flex">
                               <a className="btn d-flex align-items-center text-light font-15 ml-auto">Submit <span className="ml-2"><svg xmlns="http://www.w3.org/2000/svg"
                                                                                     width="14.25" height="10.5"
                                                                                     viewBox="0 0 14.25 10.5"><path
                                   id="Vector"
                                   d="M10.153,10.271l3.44-3.44a2.255,2.255,0,0,0,0-3.182L10.153.211a.75.75,0,0,0-1.06,1.06l3.219,3.22H.75a.75.75,0,0,0,0,1.5H12.313l-3.22,3.22a.75.75,0,1,0,1.06,1.061Z"
                                   fill="#2fecff"></path></svg></span></a>
                           </div>
                                <div className="d-flex">
                                    <a className="btn d-flex align-items-center text-light font-15 ml-auto">Already have an account? Sign in
                                        <span className="ml-2"><svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                                               width="14.25" height="10.5"
                                                                                                                                               viewBox="0 0 14.25 10.5"><path
                                        id="Vector"
                                        d="M10.153,10.271l3.44-3.44a2.255,2.255,0,0,0,0-3.182L10.153.211a.75.75,0,0,0-1.06,1.06l3.219,3.22H.75a.75.75,0,0,0,0,1.5H12.313l-3.22,3.22a.75.75,0,1,0,1.06,1.061Z"
                                        fill="#2fecff"></path></svg></span></a>
                                </div>
                            </div>
                            <div className="stormaLight radius-15 p-5 mb-5">
                                <div className="wlist">
                                    <ul>
                                        <li className="font-15"><div className="font-18 mb-2">Skip the waitlist</div>
                                            <p>Over 140K people have signed up to get access to one of most exclusive asset classes in the world.</p>
                                        </li>
                                        <li className="font-15"><div className="font-18 mb-2">Exceptional historical returns</div>
                                            <p>Our single realized investment returned 32% annualized</p>
                                        </li>
                                        <li className="font-15"><div className="font-18 mb-2">Revolutionary platform</div>
                                            <p>Our Secondary Market has provided for our investors  a means for interim liquidity</p>
                                        </li>
                                    </ul>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                    <Footer />
                </div>



           

    )
}


export default ConnectWallet;
