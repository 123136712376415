import React, { useState } from "react";
import {Modal, Button} from "react-bootstrap";
import BLogo from "../assets/images/r-user.png"

import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/custom.css";
import Footer from "../Components/Footer";

const Swap = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className="main-container st-swap">
            <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-md-10">
<div className="row">
    <div className="col-md-6">
        <div className="stormaBblack text-light radius-26 p-4">
            <div className="d-flex justify-content-center align-items-center">
                <span className="font-25 font-w600">USDT</span>
                <span className="mx-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21.833" height="21.833" viewBox="0 0 21.833 21.833">
  <path id="Path_1500" data-name="Path 1500" d="M12.917,4.183a8.733,8.733,0,1,1-8.733,8.733,8.745,8.745,0,0,1,8.733-8.733m0-2.183A10.917,10.917,0,1,0,23.833,12.917,10.921,10.921,0,0,0,12.917,2Zm1.092,10.917V8.55H11.825v4.367H8.55l4.367,4.367,4.367-4.367Z" transform="translate(-2 -2)" fill="#2fecff"/>
</svg>
                </span>
                <span className="font-25 font-w600">wSTMA</span></div>
        </div>
    </div>
    <div className="col-md-6">
        <div className="stormaBblack text-light radius-26 p-4">
            <div className="d-flex justify-content-center align-items-center">
                <span className="font-25 font-w600">wSTMA</span>
                <span className="mx-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21.833" height="21.833" viewBox="0 0 21.833 21.833">
  <path id="Path_1500" data-name="Path 1500" d="M12.917,4.183a8.733,8.733,0,1,1-8.733,8.733,8.745,8.745,0,0,1,8.733-8.733m0-2.183A10.917,10.917,0,1,0,23.833,12.917,10.921,10.921,0,0,0,12.917,2Zm1.092,10.917V8.55H11.825v4.367H8.55l4.367,4.367,4.367-4.367Z" transform="translate(-2 -2)" fill="#2fecff"/>
</svg>
                </span>
                <span className="font-25 font-w600">STMA</span></div>
        </div>
    </div>
</div>
                    <div className="row mt-4">
                        <div className="col-md-12">
                            <div className="bi-card text-light radius-26 p-4 px-5 pb-5">
                                <div className="d-flex mb-3">
                                    <p className="m-0">You Pay</p>
                                 <p className="ml-auto text-info opacity-75 mb-0">CX Balance : 5,000.00</p>
                                </div>
                                <div className="stormaBblack text-light radius-15 d-flex align-items-center pr-3">
                                    <div className="stormaBblueDark text-light radius-15 d-flex justify-content-center align-items-center font-20 font-w600 p-3 px-5 mr-3" style={{width:"200px"}}>
                                        <img className="mr-2 wh-30" src={BLogo}/> CX
                                    </div>
                                    <div className="">
                                        <input type="text" className="form-control bg-transparent font-20 font-w600 border-0 p-0" placeholder="1,000"/>
                                    </div>
                                    <p className="ml-auto text-right m-0 opacity-75">= $ 100.00</p>
                                </div>
                                <div className="d-flex mb-3 mt-5">
                                    <p className="m-0">You Receive</p>
                                    <p className="ml-auto text-info opacity-75 mb-0">wSTMA Balance : 5,000.00</p>
                                </div>
                                <div className="stormaBblack text-light radius-15 d-flex align-items-center pr-3">
                                    <div className="stormaBblueDark text-light radius-15 d-flex justify-content-center align-items-center font-20 font-w600 p-3 px-5 mr-3"  style={{width:"200px"}}>
                                        <img className="mr-2 wh-30" src={BLogo}/> wSTMA
                                    </div>
                                    <div className="">
                                        <input type="text" className="form-control bg-transparent font-20 font-w600 border-0 p-0" placeholder="13"/>
                                    </div>
                                    <p className="ml-auto text-right m-0 opacity-75">= $ 100.00</p>
                                </div>
                                <div className="d-flex mt-4">
                                    <p className="m-0 font-17">Price</p>
                                    <p className="ml-auto mb-0 font-17">1 USDT = 1 sTDAI </p>
                                </div>
                                <div className="d-flex mt-4">
                                    <p className="m-0 font-17">Minimum Swap Amount</p>
                                    <p className="ml-auto mb-0 font-17">10.00 USDT</p>
                                </div>
                                <div className="d-flex align-items-center justify-content-center mt-4">
                                    <a href="" className="btn btn-primary radius-10 nav-link font-25 p-3 px-5">Approve</a>
                                    <a href="" className="btn btn-primary radius-10 nav-link ml-4 font-25 p-3 px-5">Approve</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            </div>
            <Footer />
        </div>

    )
}


export default Swap;
