import React, { useState } from "react";
import {Modal, Button} from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/custom.css";
import UpcomingOne from "../assets/images/up_1.png";
import userLogo from "../assets/images/user_01.jpg";
import OdinStormaGod from "../assets/images/Odin-StormaGod.jpg";
import user2 from "../assets/images/user_02.jpg";
import FriggStormaGod from "../assets/images/Frigg-StormaGod.jpg";
import LokiStormaGod from "../assets/images/Loki-StormaGod.jpg";
import helaStormaGod from "../assets/images/hela.jpg";

const Creations = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className="main-container st-creations">
            <div className="row justify-content-center">
                <div className="col-md-10">
                    <div className="stormaLight radius-15 p-4">
                            <div className="row">
                            <div className="col-md-4 mb-3">
                                <div className="stormaCard radius-15 position-relative">
                                    <span className="st-label position-absolute rounded-pill p-2 px-3 right-15 top-15">35h 25m 30s</span>
                                    <img className="w-100" src={UpcomingOne}/>
                                    <div className="stormaBred text-light w-100 bottom-0 left-0 radius-15 d-flex align-items-end p-3 position-absolute">
                                        <div className="d-flex align-items-center">
                                            <img className="rounded-circle w-50px mr-3" src={userLogo}/>
                                            <div className="">
                                                <p className="m-0">Thor StormaGod</p>
                                                <small>Storma</small>
                                            </div>
                                        </div>
                                        <a href="#"
                                           className="ml-auto d-flex align-items-center text-light more">View<span
                                            className="ml-2"><svg xmlns="http://www.w3.org/2000/svg" width="14.25"
                                                                  height="10.5" viewBox="0 0 14.25 10.5"><path
                                            id="Vector"
                                            d="M10.153,10.271l3.44-3.44a2.255,2.255,0,0,0,0-3.182L10.153.211a.75.75,0,0,0-1.06,1.06l3.219,3.22H.75a.75.75,0,0,0,0,1.5H12.313l-3.22,3.22a.75.75,0,1,0,1.06,1.061Z"
                                            fill="#fff"></path></svg></span></a>
                                    </div>
                                </div>
                            </div>
                                <div className="col-md-4 mb-3">
                                    <div className="stormaCard radius-15 position-relative">
                                        <span className="st-blabel position-absolute rounded-pill p-2 px-3 right-15 top-15">35h 25m 30s</span>
                                        <img className="w-100" src={LokiStormaGod}/>
                                        <div className="stormaBred text-light w-100 bottom-0 left-0 radius-15 d-flex align-items-end p-3 position-absolute">
                                            <div className="d-flex align-items-center">
                                                <img className="rounded-circle w-50px mr-3" src={userLogo}/>
                                                <div className="">
                                                    <p className="m-0">Loki StormaGod</p>
                                                    <small>Storma</small>
                                                </div>
                                            </div>
                                            <a href="#" className="ml-auto d-flex align-items-center text-light more">View<span
                                                className="ml-2"><svg xmlns="http://www.w3.org/2000/svg" width="14.25"
                                                                      height="10.5" viewBox="0 0 14.25 10.5"><path
                                                id="Vector"
                                                d="M10.153,10.271l3.44-3.44a2.255,2.255,0,0,0,0-3.182L10.153.211a.75.75,0,0,0-1.06,1.06l3.219,3.22H.75a.75.75,0,0,0,0,1.5H12.313l-3.22,3.22a.75.75,0,1,0,1.06,1.061Z"
                                                fill="#fff"></path></svg></span></a>
                                        </div>
                                    </div>
                                </div>
                            <div className="col-md-4 mb-3">
                                <div className="stormaCard radius-15 position-relative">
                                    <span className="st-label position-absolute rounded-pill p-2 px-3 right-15 top-15">35h 25m 30s</span>
                                    <img className="w-100" src={OdinStormaGod}/>
                                    <div className="stormaBblack text-light w-100 bottom-0 left-0 radius-15 d-flex align-items-end p-3 position-absolute">
                                        <div className="d-flex align-items-center">
                                            <img className="rounded-circle w-50px mr-3" src={user2}/>
                                            <div className="">
                                                <p className="m-0">Odin StormaGod</p>
                                                <small>Storma</small>
                                            </div>
                                        </div>
                                        <a href="#"
                                           className="ml-auto d-flex align-items-center text-light more">View<span
                                            className="ml-2"><svg xmlns="http://www.w3.org/2000/svg" width="14.25"
                                                                  height="10.5" viewBox="0 0 14.25 10.5"><path
                                            id="Vector"
                                            d="M10.153,10.271l3.44-3.44a2.255,2.255,0,0,0,0-3.182L10.153.211a.75.75,0,0,0-1.06,1.06l3.219,3.22H.75a.75.75,0,0,0,0,1.5H12.313l-3.22,3.22a.75.75,0,1,0,1.06,1.061Z"
                                            fill="#fff"></path></svg></span></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className="stormaCard radius-15 position-relative">
                                    <span className="st-blabel position-absolute rounded-pill p-2 px-3 right-15 top-15">35h 25m 30s</span>
                                    <img className="w-100" src={FriggStormaGod}/>
                                    <div className="stormaBblack text-light w-100 bottom-0 left-0 radius-15 d-flex align-items-end p-3 position-absolute">
                                        <div className="d-flex align-items-center">
                                            <img className="rounded-circle w-50px mr-3" src={user2}/>
                                            <div className="">
                                                <p className="m-0">Frigg StormaGod</p>
                                                <small>Storma</small>
                                            </div>
                                        </div>
                                        <a href="#"
                                           className="ml-auto d-flex align-items-center text-light more">View<span
                                            className="ml-2"><svg xmlns="http://www.w3.org/2000/svg" width="14.25"
                                                                  height="10.5" viewBox="0 0 14.25 10.5"><path
                                            id="Vector"
                                            d="M10.153,10.271l3.44-3.44a2.255,2.255,0,0,0,0-3.182L10.153.211a.75.75,0,0,0-1.06,1.06l3.219,3.22H.75a.75.75,0,0,0,0,1.5H12.313l-3.22,3.22a.75.75,0,1,0,1.06,1.061Z"
                                            fill="#fff"></path></svg></span></a>
                                    </div>
                                </div>
                            </div>
                                <div className="col-md-4 mb-3">
                                    <div className="stormaCard radius-15 position-relative">
                                        <span className="st-blabel position-absolute rounded-pill p-2 px-3 right-15 top-15">35h 25m 30s</span>
                                        <img className="w-100" src={helaStormaGod}/>
                                        <div className="stormaBblue text-light w-100 bottom-0 left-0 radius-15 d-flex align-items-end p-3 position-absolute">
                                            <div className="d-flex align-items-center">
                                                <img className="rounded-circle w-50px mr-3" src={user2}/>
                                                <div className="">
                                                    <p className="m-0">Hela StormaGod</p>
                                                    <small>Storma</small>
                                                </div>
                                            </div>
                                            <a href="#" className="ml-auto d-flex align-items-center text-light more">View<span
                                                className="ml-2"><svg xmlns="http://www.w3.org/2000/svg" width="14.25"
                                                                      height="10.5" viewBox="0 0 14.25 10.5"><path
                                                id="Vector"
                                                d="M10.153,10.271l3.44-3.44a2.255,2.255,0,0,0,0-3.182L10.153.211a.75.75,0,0,0-1.06,1.06l3.219,3.22H.75a.75.75,0,0,0,0,1.5H12.313l-3.22,3.22a.75.75,0,1,0,1.06,1.061Z"
                                                fill="#fff"></path></svg></span></a>
                                        </div>
                                    </div>
                                </div>
                        </div>

                    </div>
                </div>
            </div>


        </div>
    )
}


export default Creations;
