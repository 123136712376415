import React from 'react';

import {BrowserRouter as Router, Route, Routes,} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/custom.css";
import Home from "./Home/Home";
import Activity from "./Activity/Activity";
import Discover from "./Discover/Discover";
import Trading from "./Trading/Trading";
import About from "./About/About";
import Header from "./Components/Header";
import LeftSidebar from "./Components/LeftSidebar";
import RightSidebar from "./Components/RightSidebar";
import Login from "./Login/Login";
import ConnectWallet from "./ConnectWallet/ConnectWallet";
import SubmitCollectible from "./SubmitCollectible/SubmitCollectible";
import Creations from "./Creations/Creations";
import Collection from "./Collection/Collection";
import Overview from "./Overview/Overview";
import Settings from "./Settings/Settings";
import Swap from "./Swap/Swap";
import CollectorProfile from "./CollectorProfile/CollectorProfile";
import ProductLoki from "./ProductLoki/ProductLoki";
import ProductHela from "./ProductHela/ProductHela";
import ProductOdin from "./ProductOdin/ProductOdin";
import ProductFrigg from "./ProductFrigg/ProductFrigg";
import ProductThor from "./ProductThor/ProductThor";





const App = () => {

    return (<Router basename='/'>
        <Header />

        <LeftSidebar />
        <RightSidebar />
        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/home' element={<Home/>}/>
            <Route path='/activity' element={<Activity/>}/>
            <Route path='/discover' element={<Discover/>}/>
            <Route path='/trading' element={<Trading/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/connect_wallet' element={<ConnectWallet/>}/>
            <Route path='/login' element={<Login/>}/>
            <Route path='/submit_collectible' element={<SubmitCollectible/>}/>
            <Route path='/creations' element={<Creations/>}/>
            <Route path='/collection' element={<Collection/>}/>
            <Route path='/overview' element={<Overview/>}/>
            <Route path='/settings' element={<Settings/>}/>
            <Route path='/swap' element={<Swap/>}/>
            <Route path='/collector-profile' element={<CollectorProfile/>}/>
            <Route path='/product-loki' element={<ProductLoki/>}/>
            <Route path='/product-hela' element={<ProductHela/>}/>
            <Route path='/product-odin' element={<ProductOdin/>}/>
            <Route path='/product-frigg' element={<ProductFrigg/>}/>
            <Route path='/product-thor' element={<ProductThor/>}/>




        </Routes>
    </Router>)
}


export default App;
