import React from 'react';

import {Container, Nav, Row} from "react-bootstrap";
import userLogo from "../assets/images/user_01.jpg"
import user2 from "../assets/images/user_02.jpg"
import user3 from "../assets/images/user_03.jpg"
import user4 from "../assets/images/user_04.jpg"
import OdinStormaGod from "../assets/images/Odin-StormaGod.jpg"
import FriggStormaGod from "../assets/images/Frigg-StormaGod.jpg"
import LokiStormaGod from "../assets/images/Loki-StormaGod.jpg"

import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/custom.css";
import Footer from "../Components/Footer";
import {Link} from "react-router-dom";
import UpcomingOne from "../assets/images/up_1.png";
import UpcomingTwo from "../assets/images/up_2.png";
import UpcomingThree from "../assets/images/up_3.jpg";

const Trading = () => {

    return (
        <div className="main-container st-trading">
        <div className="container-fluid">
           <div className="row">
               <div className="col-12">
                   <div className="stormaLight radius-15 p-5 mb-4 card-info-2">
                       <div className="d-flex justify-content-center align-items-center radius-12 mb-3">
                           <div><p className="m-0 mb-3">Buy Shares</p>
                           <p className="text-info">Buy shares or counter below the asking price by clicking "respond" on any of the offers below. Currently, the secondary market is only available to US citizens with US bank accounts. Shares held by an individual or joint account are eligible to be traded.</p>
                           </div>
                               <div className="input-group in-custom radius-6 col-4 p-0 ml-4">
                               <input type="text" className="form-control bg-transparent border-0" placeholder="Search Collectibles"
                                      aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                               <div className="input-group-append">
                                   <button className="btn" type="button">
                                       <i className="bi bi-search m-0"></i>
                                   </button>
                               </div>
                           </div>
                       </div>
                       <div className="table-responsive">
                           <table className="table text-light mb-0">

                               <tr className="no-border">
                                   <td scope="col" width={300} className="pl-0">Collectible</td>
                                   <td scope="col" className="text-center">Ask(USD)</td>
                                   <td scope="col" className="text-center">Shares</td>
                                   <td scope="col" className="text-center">Total(USD)</td>
                                   <td scope="col"  className="pr-0"></td>
                               </tr>
                               <tbody>
                               <tr>
                                   <td className="pl-0">
                                       <div className="d-flex align-items-center">
                                        <img className="w-50px mr-3 radius-6" src={OdinStormaGod} />  Odin StormaGod - Storma
                                       </div>
                                   </td>
                                   <td className="text-center">$27.25</td>
                                   <td className="text-center">1</td>
                                   <td className="text-center">$27.25</td>
                                   <td className="pr-0 text-right"><a href="" className="btn btn-primary p-2 px-3 radius-6">Buy/Counter</a></td>
                               </tr>
                               <tr>
                                   <td className="pl-0">
                                       <div className="d-flex align-items-center">
                                           <img className="w-50px mr-3 radius-6" src={LokiStormaGod} /> Loki StormaGod - Storma
                                       </div>
                                   </td>
                                   <td className="text-center">$27.25</td>
                                   <td className="text-center">1</td>
                                   <td className="text-center">$27.25</td>
                                   <td className="pr-0 text-right"><a href="" className="btn btn-primary p-2 px-3 radius-6">Buy/Counter</a></td>
                               </tr>
                               <tr>
                                   <td className="pl-0">
                                       <div className="d-flex align-items-center">
                                           <img className="w-50px mr-3 radius-6" src={FriggStormaGod} /> Frigg StormaGod - Storma
                                       </div>
                                   </td>
                                   <td className="text-center">$27.25</td>
                                   <td className="text-center"> 1</td>
                                   <td className="text-center">$27.25</td>
                                   <td className="pr-0 text-right"><a href="" className="btn btn-primary p-2 px-3 radius-6">Buy/Counter</a></td>
                               </tr>
                               <tr>
                                   <td className="pl-0">
                                       <div className="d-flex align-items-center">
                                           <img className="w-50px mr-3 radius-6" src={UpcomingOne} />   Thor StormaGod - Storma
                                       </div>
                                   </td>
                                   <td className="text-center">$27.25</td>
                                   <td className="text-center">1</td>
                                   <td className="text-center">$27.25</td>
                                   <td className="pr-0 text-right"><a href="" className="btn btn-primary p-2 px-3 radius-6">Buy/Counter</a></td>
                               </tr>
                               <tr>
                                   <td className="pl-0">
                                       <div className="d-flex align-items-center">
                                           <img className="w-50px mr-3 radius-6" src={UpcomingTwo} />   WCAR Reveal #1 - WCAR
                                       </div>
                                   </td>
                                   <td className="text-center">$27.25</td>
                                   <td className="text-center">1</td>
                                   <td className="text-center">$27.25</td>
                                   <td className="pr-0 text-right"><a href="" className="btn btn-primary p-2 px-3 radius-6">Buy/Counter</a></td>
                               </tr>
                               <tr>
                                   <td className="pl-0">
                                       <div className="d-flex align-items-center">
                                           <img className="w-50px mr-3 radius-6" src={UpcomingThree} />   WCAR Reveal #3 - WCAR
                                       </div>
                                   </td>
                                   <td className="text-center">$27.25</td>
                                   <td className="text-center">1</td>
                                   <td className="text-center">$27.25</td>
                                   <td className="pr-0 text-right"><a href="" className="btn btn-primary p-2 px-3 radius-6">Buy/Counter</a></td>
                               </tr>


                               </tbody>
                           </table>
                       </div>
                   </div>
               </div>

           </div>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="stormaLight radius-15 p-4 mb-4 card-info-2">
                        <div className="d-flex justify-content-center align-items-center radius-12 p-4">
                            <div><p className="m-0 mb-3">Risk Of Investing</p>
                                <p className="m-0">Investing involves a number of significant risks and uncertainties. Please click here for a summary discussion  of risk factors you should consider prior to investing. Please also review the "Risk Factors" section of our latest  SEC filings prior to investing.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
       
      </div>
    )
}


export default Trading;
