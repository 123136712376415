import React from 'react';

import { Container, Row} from "react-bootstrap";

import Decentralization from "../assets/images/Decentralization.png"

import About1 from "../assets/images/about_1.png"
import About2 from "../assets/images/about_2.png"
import About3 from "../assets/images/about_3.png"
import About4 from "../assets/images/about_4.png"
import Collect from "../assets/images/collect.png"
import ShowOff from "../assets/images/show-off.png"
import Invest from "../assets/images/invest.png"
import Group from "../assets/images/group.png"
import WeBuy from "../assets/images/We-Buy.png"
import Sellshares from "../assets/images/Sell-shares.png"



import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/custom.css";
import Footer from "../Components/Footer";

const About = () => {

    return (
        <div className="main-container bottom-light right-light st-about">
        <div className="container-fluid">
        <div className="row">
                <div className="col-md-12">
                   
                    <div className="stormaLight radius-15 p-5 mb-4 abt-1">
                        <div className="row align-items-center">
                        <div className="col-md-6  order-2">
                        
                        <p>Every digital creation available through Storma is an authentic and truly unique digital creation, signed and issued by the creator — made possible by blockchain technology. Even if the digital creation is copied, it won't be the authentic and originally signed version.</p></div>
                        <div className="col-md-6 order-1"><img className="img-fluid" src={About1}/></div>
                        </div>
                    </div>
                    <div className="stormaLight radius-15 p-5 mb-4 abt-2">
                        <div className="row align-items-center">
                        <div className="col-md-6 order-1 text-right">
                        <h2 className="mb-3 text-light font-w400 font-30">Authentic</h2>
                        <p>Every digital creation on Storma is digitally signed by the creator and permanently recorded and verified through the blockchain.</p></div>
                        <div className="col-md-6 order-2 text-right"><img className="img-fluid" src={About2}/></div>
                        </div>
                    </div>
                    <div className="stormaLight radius-15 p-5 mb-4 abt-3">
                        <div className="row align-items-center">
                        <div className="col-md-6 order-2">
                        <h2 className="mb-3 text-light font-w400 font-30">Unique</h2>
                        <p>Every digital creation is issued as a unique digital edition. Using blockchain technology, a creator can ensure that only a limited number of authentic editions can ever be owned, ensuring scarcity and uniqueness of a digital creation.</p></div>
                        <div className="col-md-6 order-1"><img className="img-fluid" src={About3}/></div>
                        </div>
                    </div>
                    <div className="stormaLight radius-15 p-5 mb-4 abt-4">
                        <div className="row align-items-center">
                        <div className="col-md-6 order-1 text-right">
                        <h2 className="mb-3 text-light font-w400 font-30">Ownable</h2>
                        <p>Purchasing a unique digital creation means you're given full ownership over the creation, which is then transferred and stored in your digital wallet for safe-keeping.</p></div>
                        <div className="col-md-6 order-2 text-right"><img className="img-fluid" src={About4}/></div>
                        </div>
                    </div>
                  
                </div>
            </div>
            <div className="row my-5 about-second">
                <div className="col-12"><h2 className="mb-3 text-light font-w400 font-30">Start Your Own Collection Today</h2></div>
                <div className="col-md-4 d-flex">
                <div className="stormaLight text-light radius-15 d-flex p-3 w-100">
                 <div className="d-flex align-items-center">
                 <div className="circle-custom w-70px h-70px rounded-circle mr-3">
                     <img className="ml-3 mt-3" src={Collect}/>
                 </div>
                 <div className="">
                 <p className="mb-2 head font-17">Collect</p>
                 <p className="m-0 font-14">Collect unique works from
amazing digital creators.</p>
                 </div>
                    </div> 
                   
               </div>
                </div>
                <div className="col-md-4 d-flex">
                <div className="stormaLight text-light radius-15 d-flex p-3 w-100">
                 <div className="d-flex align-items-center">
                     <div className="circle-custom w-70px h-70px rounded-circle d-flex align-items-center mr-3">
                         <img className="" src={ShowOff}/>
                     </div>

                 <div className="">
                 <p className="mb-2 head font-17">Show off</p>
                 <p className="m-0 font-14">Show off your collection to your friends and the community.</p>
                 </div>
                    </div>
               </div>
                </div>
                <div className="col-md-4 d-flex">
                <div className="stormaLight text-light radius-15 d-flex p-3 w-100">
                 <div className="d-flex align-items-center">
                     <div className="circle-custom w-70px h-70px rounded-circle d-flex align-items-center mr-3">
                         <img className="" src={Invest}/>
                     </div>
                 <div className="">
                 <p className="mb-2 head font-17">Invest</p>
                 <p className="m-0 font-14">Invest in a creator. You can resell your creation on any digital asset exchange.</p>
                 </div>
                    </div> 
                   
               </div>
                </div>
            </div>
            <div className="row">
            <div className="col-12">
            <div className="stormaLight radius-15 p-5 mb-4 how-work">
            <h2 className="mb-3 text-light font-w400 font-30">How It Works</h2>
            <div className="row justify-content-end">
            <div className="col-md-4">
                <div className="stormaLight offLight radius-right-50 text-light radius-26 d-flex">
                 <div className="d-flex align-items-center">
                     <div className="circle-custom w-90px h-90px rounded-circle order-2 d-flex align-items-center justify-content-center">
                         <span>
                             <svg id="Group_170" data-name="Group 170" xmlns="http://www.w3.org/2000/svg" width="52.271" height="52.277" viewBox="0 0 52.271 52.277">
  <path id="Path_126" data-name="Path 126" d="M404.414,257.294v5.175H386.239v18.186h-5.111c0-6.387-.2-12.759.1-19.107.125-2.62,2.277-4.19,5-4.228C392.181,257.236,398.139,257.294,404.414,257.294Z" transform="translate(-381.01 -257.274)" fill="#000238"/>
  <path id="Path_127" data-name="Path 127" d="M443.462,262.471H425.255v-4.992c6.406,0,12.881-.236,19.325.116,2.593.142,3.99,2.461,4,5.182.033,5.853.01,11.708.01,17.809h-5.133Z" transform="translate(-396.332 -257.318)" fill="#000238"/>
  <path id="Path_128" data-name="Path 128" d="M404.374,324.9c-6.233,0-12.189.084-18.14-.034a4.97,4.97,0,0,1-5.185-5.154c-.124-5.954-.037-11.913-.037-18.089h5.176V319.7h18.186Z" transform="translate(-380.983 -272.649)" fill="#000238"/>
  <path id="Path_129" data-name="Path 129" d="M425.228,324.887V319.8H443.2V301.641h5.327c0,6.233.166,12.5-.078,18.748a4.576,4.576,0,0,1-4.794,4.453C437.6,324.987,431.536,324.887,425.228,324.887Z" transform="translate(-396.323 -272.656)" fill="#000238"/>
  <path id="Path_130" data-name="Path 130" d="M397.387,314.1l10.147-12.683,7.786,9.457,5.388-6.826,7.559,10.052Z" transform="translate(-386.67 -272.577)" fill="#000238"/>
  <path id="Path_131" data-name="Path 131" d="M433.118,285.163c-2.383-.221-3.96-1.418-3.993-3.858a3.843,3.843,0,0,1,7.679-.3C436.944,283.45,435.46,284.75,433.118,285.163Z" transform="translate(-397.674 -264.25)" fill="#000238"/>
</svg>
                         </span>
                     </div>
                 <div className="order-1 text-right px-3">
                 <p className="mb-2 head font-13">3 -10 Year Hold Period </p>
                 <p className="m-0 font-10">Wait until we sell the painting to receive
                     your pro rata proceeds, after our fees</p>
                 </div>
                    </div> 
                   
               </div>
                </div>
            </div>
            <div className="row my-3">
            <div className="col-md-4">
                <div className="stormaLight offLight radius-left-50  text-light radius-26 d-flex">
                 <div className="d-flex align-items-center">
                     <div className="circle-custom w-90px h-90px rounded-circle d-flex align-items-center justify-content-center">
                         <span>
                             <svg id="Group_164" data-name="Group 164" xmlns="http://www.w3.org/2000/svg" width="54.311" height="52.318" viewBox="0 0 54.311 52.318">
  <path id="Path_121" data-name="Path 121" d="M409.346,288.017l18.737-18.723c2.144-2.142,4.317-4.257,6.425-6.436,1.921-1.985,3.737-1.931,5.6.059.91.974,2.29,1.739,2.715,2.876.5,1.34.871,3.635.138,4.391-8.422,8.687-17.066,17.16-25.743,25.783Z" transform="translate(-389.059 -261.397)" fill="#000238"/>
  <path id="Path_122" data-name="Path 122" d="M381.605,320.021c3.709-1.039,5.185-3.455,5.554-7.2a8.545,8.545,0,0,1,9.876-7.438,8.936,8.936,0,0,1,7.273,10.431,11.678,11.678,0,0,1-15.637,9.092c-2.535-.868-4.782-2.578-7.16-3.9Z" transform="translate(-381.511 -273.296)" fill="#000238"/>
</svg>
                         </span>
                     </div>
                 <div className="px-3">
                     <p className="mb-2 head font-13">We Find the Best Artists</p>
                     <p className="m-0 font-10">Our research team uses our proprietary data to
                         determine which artist markets have the most
                         momentum and we believe produce the best
                         risk-adjusted returns</p>
                 </div>
                    </div> 
                   
               </div>
                </div>
                <div className="col-md-4">
                <div className="stormaLight offLight  radius-left-50 text-light radius-26 d-flex">
                 <div className="d-flex align-items-center">
                     <div className="circle-custom w-90px h-90px rounded-circle d-flex align-items-center justify-content-center">

                          <span>
                             <svg id="Group_165" data-name="Group 165" xmlns="http://www.w3.org/2000/svg" width="54.751" height="54.748" viewBox="0 0 54.751 54.748">
  <path id="Path_123" data-name="Path 123" d="M411.977,315.992c-6.585,0-13.17.04-19.756-.013-4.956-.04-7.191-2.253-7.206-7.211q-.064-20.135,0-40.271c.016-4.958,2.251-7.191,7.21-7.207q20.135-.063,40.271,0c4.956.016,7.191,2.251,7.206,7.211q.064,20.134,0,40.27c-.016,4.958-2.251,7.17-7.21,7.208C425.653,316.032,418.815,315.992,411.977,315.992Zm21.571-9.247L419.97,288.668l-10.62,13.553-7.709-8.928-10.455,13.452Z" transform="translate(-384.983 -261.259)" fill="#000238"/>
</svg>
                         </span>
                     </div>
                 <div className="px-3">
                     <p className="mb-2 head font-13">We Purchase the Best Art</p>
                     <p className="m-0 font-10">Our acquisitions team locates the best
                         example available, at the best price, and
                         purchase the work</p>
                 </div>
                    </div> 
                   
               </div>
                </div>
                <div className="col-md-4">
                <div className="stormaLight offLight  radius-left-50 text-light radius-26 d-flex">
                 <div className="d-flex align-items-center">
                     <div className="circle-custom w-90px h-90px rounded-circle d-flex align-items-center justify-content-center">
                         <span><svg id="Group_166" data-name="Group 166" xmlns="http://www.w3.org/2000/svg" width="48.597" height="58.049" viewBox="0 0 48.597 58.049">
  <path id="Path_124" data-name="Path 124" d="M433.419,275.769c.319,5.779-.177,12.635-3.34,19.069-3.856,7.845-9.588,13.756-17.9,16.741a9.689,9.689,0,0,1-6.038-.019c-11.873-4.195-20.682-16.671-21.114-29.691-.17-5.14-.173-10.294.035-15.431a4.32,4.32,0,0,1,2.1-3.091c6.664-3.157,13.412-6.144,20.2-9.019a5.268,5.268,0,0,1,3.711.022c6.691,2.826,13.276,5.9,19.961,8.741,1.82.774,2.563,1.746,2.425,3.742C433.284,269.4,433.419,271.977,433.419,275.769Zm-24.189,7.214v23.738c9.665-2.436,18.5-13.954,18.374-23.644h-18.47V259.389c-6.137,2.714-11.855,5.21-17.528,7.805a2.374,2.374,0,0,0-1.2,1.7c-.1,4.65-.054,9.3-.054,14.089Z" transform="translate(-384.906 -254.003)" fill="#000238"/>
</svg></span>
                     </div>
                 <div className="px-3">
                     <p className="mb-2 head font-13">We Find the Best Artists</p>
                     <p className="m-0 font-10">We file an offering circular with the
                         Securities and Exchange Commission
                         allowing anyone to invest.</p>
                 </div>
                    </div> 
                   
               </div>
                </div>
            </div>
            <div className="row justify-content-end">
            <div className="col-md-4">
                <div className="stormaLight offLight  radius-right-50 text-light radius-26 d-flex">
                 <div className="d-flex align-items-center">
                     <div className="circle-custom w-90px h-90px rounded-circle order-2 d-flex align-items-center justify-content-center">
                         <span>
                             <svg xmlns="http://www.w3.org/2000/svg" width="61.231" height="61.23" viewBox="0 0 61.231 61.23">
  <g id="Group_169" data-name="Group 169" transform="translate(21.628) rotate(45)">
    <g id="Group_113" data-name="Group 113" transform="translate(0 0)">
      <path id="Path_76" data-name="Path 76" d="M55.943,3.5a1.272,1.272,0,0,1,.063.389V26.9a.625.625,0,0,0-.113.165,4.276,4.276,0,0,1-4.516,3.513q-18.23.009-36.46-.012a2.921,2.921,0,0,1-1.784-.737Q7.054,23.79,1,17.717a3.424,3.424,0,0,1,0-4.839q6-6.008,12-12.021A2.908,2.908,0,0,1,15.069,0q15.24.019,30.48.01c2.039,0,4.078-.023,6.116.007a4.2,4.2,0,0,1,4,2.7A7.2,7.2,0,0,1,55.943,3.5Z" fill="#000238"/>
    </g>
    <path id="Path_125" data-name="Path 125" d="M3.356,0A3.356,3.356,0,1,1,0,3.356,3.356,3.356,0,0,1,3.356,0Z" transform="translate(9.638 11.938)" fill="#2fecff"/>
  </g>
</svg>
                         </span>
                     </div>
                 <div className="order-1 text-right px-3">
                     <p className="mb-2 head font-13">Sell Your Shares</p>
                     <p className="m-0 font-10">You have the option to sell your shares in
                         our secondary market.</p>
                 </div>
                    </div> 
                   
               </div>
                </div>
            </div>
                    </div>
            <div className="stormaLight radius-15 p-5 mb-4 card-info-2">
                        <div className="row align-items-center">
                        <div className="col-md-6 order-2">
                        <h2 className="mb-3 text-light font-w400 font-30">Identify The Artists<br/>
With Momentum</h2>
                        <p>We use a proprietary data set to understand
which artist markets are accelerating the most 
quickly and provide the best risk-adjusted returns. As a member, you can access this database for  free to do your own research.</p></div>
                        <div className="col-md-6 order-1"><img className="img-fluid" src={Group}/></div>
                        </div>
                    </div>
                    <div className="stormaLight radius-15 p-5 mb-4 card-info-1">
                        <div className="row align-items-center">
                        <div className="col-md-6 order-1 text-right">
                        <h2 className="mb-3 text-light font-w400 font-30">We Buy The Best Examples<br/>
At The Best Prices</h2>
                        <p>Once we decide which artist markets are appreciating at auction, our acquisitions team locates and chooses the best example available by value to offer to our members.</p></div>
                        <div className="col-md-6 order-2 text-right"><img className="img-fluid" src={WeBuy}/></div>
                        </div>
                    </div>
                <div className="row justify-content-center">
                    <div className="col-11">
                        <div className="row align-items-center">
                            <div className="col-md-5">
                                <div className="bi-card py-4">
                                    <div className="px-3">
                                        <img className="w-100" src={Sellshares}/>
                                    </div>
                                    <div className="font-17 py-3 px-2">Sell Shares</div>
                                    <div className="bg-light radius-26 p-3 font-14 text-theme">
                                        <div className="d-flex mb-2">
                                            Available shares
                                            <span className="ml-auto">500</span>
                                        </div>
                                        <div className="d-flex mb-2">
                                            Ask Price
                                            <span className="ml-auto">$24.75</span>
                                        </div>
                                        <div className="d-flex mb-2">
                                            Quantity
                                            <span className="ml-auto">25</span>
                                        </div>
                                        <div className="d-flex mb-2">
                                            Transaction Fees
                                            <span className="ml-auto">$0.00</span>
                                        </div>
                                        <div className="d-flex">
                                            Total
                                            <span className="ml-auto">$618.75</span>
                                        </div>
                                    </div>
                                    <div className="text-center mt-3">
                                        <a href="#" className="btn bg-transparent text-light">CONFIRM</a>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-7">
                                <div className="row">
                                    <div className="col-md-4">
                                       <div className="sellShare-col">
                                            <div className="mb-3">
                                                <svg id="Group_618" data-name="Group 618" xmlns="http://www.w3.org/2000/svg" width="37.853" height="29.886" viewBox="0 0 37.853 29.886">
                                                    <g id="Group_172" data-name="Group 172" transform="translate(0 6.029)">
                                                        <path id="Path_132" data-name="Path 132" d="M401.788,299.339a3.376,3.376,0,0,1-3.545-3.025c-.4-2.648-2.6-3.776-4.3-5.291-.692-.617-1.619-.132-2.281.534-2.007,2.019-4.013,4.04-6.039,6.04a3.525,3.525,0,0,0-.876,2.1,3.546,3.546,0,0,1-7.047-.264,3.356,3.356,0,0,1,3.022-3.734,4.437,4.437,0,0,0,2.712-1.491q2.022-2.055,4.081-4.074a6.534,6.534,0,0,0,2.32-3.9c.237-1.687,1.652-2.594,3.456-2.548a3.112,3.112,0,0,1,3.336,2.721c.317,2.721,2.541,3.81,4.127,5.434a1.589,1.589,0,0,0,2.314-.222c1.625-1.6,3.22-3.221,4.826-4.836a3.04,3.04,0,0,0,.585-1.782,3.553,3.553,0,1,1,4.04,4.044c-2.378.5-6.685,4.9-7.261,7.37A3.493,3.493,0,0,1,401.788,299.339Z" transform="translate(-377.696 -278.879)" fill="#ffac45"/>
                                                        <path id="Path_133" data-name="Path 133" d="M378.623,278.81c2.394-.442,3.484-1.813,4.008-4.078.52,2.275,1.61,3.649,4.021,3.963-2.02.771-3.77,1.581-3.925,4.183C382.066,280.685,381.066,279.144,378.623,278.81Z" transform="translate(-378.227 -274.732)" fill="#ffac45"/>
                                                    </g>
                                                    <path id="Path_134" data-name="Path 134" d="M423.035,265.631c2.942-.543,4.282-2.228,4.926-5.012.64,2.8,1.978,4.484,4.943,4.871-2.483.948-4.634,1.944-4.825,5.141C427.267,267.936,426.037,266.041,423.035,265.631Z" transform="translate(-403.667 -260.619)" fill="#ffac45"/>
                                                </svg>
                                            </div>
                                           <div className="font-14 mb-2">Easy Access</div>
                                           <p className="font-10 m-0">Shares will be available
                                               for transaction 90 days after
                                               the primary offering closing</p>
                                       </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="sellShare-col">
                                            <div className="mb-3">
                                                <svg id="Group_619" data-name="Group 619" xmlns="http://www.w3.org/2000/svg" width="30.761" height="34.06" viewBox="0 0 30.761 34.06">
                                                    <path id="Path_135" data-name="Path 135" d="M385,281.388h5.047V257.636l2.655,2.223,2.539-2.287,2.564,2.315,2.546-2.307,2.594,2.294,2.543-2.3,2.574,2.306,2.558-2.32,2.548,2.314,2.445-2.135c.058.838.135,1.437.135,2.036q.012,12.8,0,25.6c0,4.341-1.893,6.227-6.232,6.234q-9.281.014-18.562,0c-3.944-.011-5.915-1.971-5.955-5.868C384.987,284.39,385,283.043,385,281.388Zm27.337-18.679H393.7v18.776H408.92a23.114,23.114,0,0,0,.077,4.465c.17.817,1.1,1.476,1.69,2.206.549-.7,1.567-1.39,1.577-2.1C412.378,278.327,412.335,270.592,412.335,262.709Z" transform="translate(-384.994 -257.558)" fill="#ffac45"/>
                                                    <path id="Path_136" data-name="Path 136" d="M419.249,280.806h-10v-3.16h10Z" transform="translate(-398.89 -269.065)" fill="#ffac45"/>
                                                    <path id="Path_137" data-name="Path 137" d="M409.09,292.783V289.65h10.064v3.133Z" transform="translate(-398.796 -275.941)" fill="#ffac45"/>
                                                    <path id="Path_138" data-name="Path 138" d="M437.307,277.364h3.118v3.23h-3.118Z" transform="translate(-414.959 -268.903)" fill="#ffac45"/>
                                                    <path id="Path_139" data-name="Path 139" d="M440.42,292.74h-3.144v-3.233h3.144Z" transform="translate(-414.941 -275.859)" fill="#ffac45"/>
                                                </svg>
                                            </div>
                                            <div className="font-14 mb-2">No transaction fees</div>
                                            <p className="font-10 m-0">Shares will be available for
                                                transaction 90 days after
                                                the primary offering closing</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="sellShare-col">
                                            <div className="mb-3">
                                                <svg id="Group_620" data-name="Group 620" xmlns="http://www.w3.org/2000/svg" width="34.464" height="34.467" viewBox="0 0 34.464 34.467">
                                                    <path id="Path_140" data-name="Path 140" d="M398.717,292.4a17.234,17.234,0,1,1,17.408-16.951A17.034,17.034,0,0,1,398.717,292.4Zm4.043-13.04a2.818,2.818,0,0,1-1.668,2.677c-1.992,1.065-3.876.511-5.621-1.876a1.361,1.361,0,0,0-2.13-.434c-.921.553-.424,1.214-.083,1.823a5.571,5.571,0,0,0,2.376,2.381c1.017.5,2.12.851,2.166,2.31.019.59.6.715,1.136.71s1.042-.182,1.093-.779c.106-1.25,1.033-1.551,1.977-1.936a4.608,4.608,0,0,0,3.18-3.918c.306-2.807-.744-4.63-3.519-5.906A47.729,47.729,0,0,1,397,272.365c-1.848-1.107-1.6-3.289.365-4.181a3.514,3.514,0,0,1,4.436.944,1.449,1.449,0,0,0,2.253.037c.833-.713-.049-1.275-.463-1.754a5.227,5.227,0,0,0-1.486-1.185c-.941-.493-1.993-.776-2.093-2.127-.044-.6-.6-.719-1.136-.717s-1.034.16-1.106.764A2.15,2.15,0,0,1,396.258,266a4.7,4.7,0,0,0-3.049,4.495c-.028,2.162,1.352,3.532,3.19,4.409,1.343.642,2.77,1.107,4.134,1.709A3.514,3.514,0,0,1,402.76,279.361Z" transform="translate(-381.661 -257.935)" fill="#ffac45"/>
                                                </svg>
                                            </div>
                                            <div className="font-14 mb-2">Lower minimums</div>
                                            <p className="font-10 m-0">Invest on the secondary
                                                market with lower minimums
                                                than primary offerings</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="stormaLight radius-15 p-5 mt-4 mb-4 management-about">
                    <div className="row align-items-center">
                        <div className="col-md-6 order-1 text-right">
                            <h2 className="mb-3 text-light font-w400 font-30">Management Fees</h2>
                            <p>Masterworks is the first platform for buying and selling shares representing an investment in iconic artworks. Build a diversified portfolio of iconic works of art curated by our industry-leading research team</p></div>
                        <div className="col-md-6 order-2 text-right">
<div className="d-flex pl-4">
                            <div className="text-center d-flex align-items-center justify-content-center p-3 w-50 circle-custom radius-12 text-dark h-200">
                                <div>
                                <div className="font-35">1.5%</div>
                                <p className="font-18 mb-2">Fee per year</p>
                                <p className="font-10 m-0">Our annual management fee is paid
                                    in the form of equity at no
                                    out-of-pocket expense to you</p>
                                </div>
                            </div>
</div>
                            <div className="d-flex  justify-content-end pr-4 mt-n4">
                            <div className="text-center d-flex align-items-center justify-content-center w-50 bg-black p-3 radius-12 text-light h-200">
                                <div>
                                <div className="font-35">20%</div>
                                <p className="font-18 mb-2">Future profits</p>
                                <p className="font-10 m-0">We're aligned with investor interests to
                                    sell the painting for the highest possible return.</p>
                                </div>

                            </div>
                            </div>

                        </div>
                    </div>
                </div>
                    </div>
            </div>
        </div>
        <Footer />
        </div>
    )
}


export default About;
