import React from 'react';
import {Nav} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import BLogo from "../assets/images/STORMA_logo.png"
import {connect} from "react-redux";
import {setMobileNavOpenStatus} from "../redux/mobile-nav/mobile-nav-actions";

const LeftSidebar = ({isNavOpen, setMobileNavOpen})=> {
    let location = useLocation();
    const publicRoutes = [
        '/activating', '/activating2', '/connected', '/login_approve',
    ];
    if(publicRoutes.includes(location.pathname)){
        return null;
    }
    const getNavLinkClass = path => {
        return location.pathname === path
            ? "active"
            : "";
    };
    const closeMobileMenu = ()=> {
        setMobileNavOpen(false);
    }
    return <div className={`left-sidebar ${isNavOpen ? 'active': ''}`}>

        <div className="left-menu text-uppercase">
            <a className="d-block d-sm-none" href="#" onClick={closeMobileMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18" stroke="#fff" ></line><line x1="6" y1="6" x2="18" y2="18" stroke="#fff"></line></svg>
            </a>
            <Nav activeKey="home" as="ul">

                    <Nav.Item onClick={closeMobileMenu} as="li" className={getNavLinkClass("/")} >
                        <Nav.Link eventKey="home"  as={Link} to="/">
                            <i className="bi bi-dashboard"/>
                            <span>Home</span>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={closeMobileMenu} as="li"  className={getNavLinkClass("/discover")}>
                        <Nav.Link  eventKey="Discover" as={Link} to="/Discover">
                            <i className="bi bi-packages"/>
                            <span>Discover</span>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={closeMobileMenu} as="li" className={getNavLinkClass("/activity")}>
                        <Nav.Link  as={Link} to="/activity">
                            <i className="bi bi-teams"/>
                            <span>Activity</span>
                        </Nav.Link>
                    </Nav.Item>
                   
                   
                    <Nav.Item onClick={closeMobileMenu} as="li" className={getNavLinkClass("/trading")}>
                        <Nav.Link as={Link} to="/trading">
                            <i className="bi bi-stats"/>
                            <span>Trading</span>
                        </Nav.Link>
                    </Nav.Item>
                    
                    <Nav.Item onClick={closeMobileMenu} as="li" className={getNavLinkClass("/about")}>
                        <Nav.Link  as={Link} to="/about">
                            <i className="bi bi-targets"/>
                            <span>About</span>
                        </Nav.Link>
                    </Nav.Item>

            </Nav>
        </div>
        <div className="logout-wrap active d-flex justify-content-center my-4">
        <Nav.Link onClick={closeMobileMenu}  as={Link} to="/connect_wallet" className="btn btn-primary radius-10 w-100">Connect Wallet</Nav.Link>
        </div>
    </div>

}

const mapStateToProps = state => ({
    isNavOpen: state.mobileNav.leftSidebarOpen
});
const mapDispatchToProps = dispatch => ({
    setMobileNavOpen: isOpen => dispatch(setMobileNavOpenStatus(isOpen))
});
export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar);
