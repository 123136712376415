import React, { useState } from "react";
import PolygonIcon from "../assets/images/Polygon.svg"
import DollarIcon from "../assets/images/doller.png"
import WalletIcon from "../assets/images/wal.svg"
import CheckedIcon from "../assets/images/checked.png"
import ShareIcon from "../assets/images/share.png"
import MessageIcon from "../assets/images/message.png"
import Logo from "../assets/images/STORMA_logo.png"
import {Link, useLocation} from "react-router-dom"
import {setMobileNavConnectWalletOpenStatus, setMobileNavOpenStatus, setRightMobileNavOpenStatus} from "../redux/mobile-nav/mobile-nav-actions";
import {connect} from "react-redux";
import {Modal, Button, Nav} from "react-bootstrap";
import TopActions from "./Mobile/TopActions";

const Header = ({setMobileNavOpen,setRightMobileNavOpen, isNavOpen, isRightNavOpen, isConnectWalletOpen, setConnectWalletOpen}) => {

    const [show, setShow] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let location = useLocation();
    const publicRoutes = [
        '/activating', '/activating2', '/connected', '/login_approve',
    ];
    if (publicRoutes.includes(location.pathname)) {
        return null;
    }
    const toggleSearch = () => {
        setShowSearch(!showSearch);
    }
    const toggleMobileMenu = () => {
        setMobileNavOpen(!isNavOpen);
        //setRightMobileNavOpen(!isRightNavOpen);
    }
    const toggleRightMobileMenu = () => {
        setRightMobileNavOpen(!isRightNavOpen);
    }
    const toggleConnectWallet = () => {
        setMobileNavConnectWalletOpenStatus(!isConnectWalletOpen);
    }
    return <div className="header">
        <div className="d-flex flex-wrap align-items-center">
            <div className="d-flex align-items-center">
                <div className="logo-wrap text-center d-flex align-items-center">
                    <a href="#">
                        <img src={Logo}/>
                    </a>
                    <span className="toggle-menu ml-3 mr-2" onClick={toggleMobileMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
                 className="feather feather-align-left"><line x1="17" y1="10" x2="3" y2="10"></line><line x1="21" y1="6"
                                                                                                      x2="3"
                                                                                                      y2="6"></line><line
                x1="21" y1="14" x2="3" y2="14"></line><line x1="17" y1="18" x2="3" y2="18"></line></svg>
            </span>
                    <span className="toggle-menu ml-2 mr-3 profile-menu" onClick={toggleRightMobileMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
<path d="M272 304h-96C78.8 304 0 382.8 0 480c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32C448 382.8 369.2 304 272 304zM48.99 464C56.89 400.9 110.8 352 176 352h96c65.16 0 119.1 48.95 127 112H48.99zM224 256c70.69 0 128-57.31 128-128c0-70.69-57.31-128-128-128S96 57.31 96 128C96 198.7 153.3 256 224 256zM224 48c44.11 0 80 35.89 80 80c0 44.11-35.89 80-80 80S144 172.1 144 128C144 83.89 179.9 48 224 48z"/>
</svg>
            </span>
                </div>
                <div className="">
                    <div className="input-group">
                        <div className={`search-clicked ${showSearch ? 'open' : ''}`}>

                            <input type="text"
                                                               className="form-control border-0 bg-transparent p-0"
                                                               style={{width: "260px"}}
                                                               placeholder="Search for anything here..."/>
                                                                <button
                                className="btn btn-secondary p-0 bg-transparent border-0 d-flex align-items-center ds-none mx-3"
                                type="button">
                                <i className="bi bi-search m-0"></i>
                            </button>
                            <a className="ds-none" onClick={toggleSearch} href="#">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18" stroke="#fff" ></line><line x1="6" y1="6" x2="18" y2="18" stroke="#fff"></line></svg>
            </a>
                        </div>
                        <div className="input-group-append">
                            <button
                                onClick={toggleSearch}
                                className="btn btn-secondary p-0 bg-transparent border-0 d-flex align-items-center"
                                type="button">
                                <i className="bi bi-search m-0"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex ml-auto align-items-center">
                <TopActions />

               <a href="#" className="btn btn-primary bi-border rounded-pill mh-52 p-2 px-4 mb-none">
                Buy STMA
                </a>
                <div className="p-3 notification d-none">
                    <Nav.Link as={Link} to="/login" className="text-light d-flex align-items-center">
                        Sign In
                        <span className="ml-2">
                   <svg xmlns="http://www.w3.org/2000/svg" width="14.25" height="10.5" viewBox="0 0 14.25 10.5">
  <path id="Vector" d="M10.153,10.271l3.44-3.44a2.255,2.255,0,0,0,0-3.182L10.153.211a.75.75,0,0,0-1.06,1.06l3.219,3.22H.75a.75.75,0,0,0,0,1.5H12.313l-3.22,3.22a.75.75,0,1,0,1.06,1.061Z" fill="#2fecff"/>
</svg>
                   </span>
                    </Nav.Link>

                </div>
            </div>
        </div>

        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>

                    <div>
                        <div className="font-15 text-light mb-3"><strong>Connect Wallet </strong></div>
                        <div className="bi-card bicard-light p-3 ml-auto font-15 mb-2 radius-12">
                            <div className="d-flex align-items-center"><span
                                className="text-warning mr-3">0xD1Ef...b506</span><span
                                className="ml-auto d-flex"><a href="#" className=""><svg xmlns="http://www.w3.org/2000/svg"
                                                                                         width="19"
                                                                                         height="22" viewBox="0 0 19 22"><path
                                id="Path_3161" data-name="Path 3161"
                                d="M16,1H4A2.006,2.006,0,0,0,2,3V17H4V3H16Zm3,4H8A2.006,2.006,0,0,0,6,7V21a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,19,5Z"
                                transform="translate(-2 -1)" fill="#c1c1c1"></path></svg></a></span>
                            </div>
                        </div>
                        <div className="bi-card bicard-light d-flex p-3 px-3 align-items-center mb-3 radius-12">
      <span className="d-flex align-items-center text-warning">
      <img className="mr-3" src={PolygonIcon}/> Polygon
      </span>

                        </div>
                        <div className="font-15 text-light mb-3">Current Wallet balance</div>
                        <div className="bi-card bicard-light d-flex p-3 px-3 align-items-center mb-3 radius-12">
      <span className="d-flex align-items-center text-warning">
      <img className="mr-3" src={DollarIcon}/> 40.02 USDC
      </span>

                        </div>
                        <div className="bi-card bicard-light d-flex p-3 px-3 align-items-center radius-12">
      <span className="d-flex align-items-center text-warning">
      <img className="mr-3" src={PolygonIcon}/> 0.049 MATIC
      </span>

                        </div>


                    </div>

            </Modal.Body>

        </Modal>

    </div>
}
const mapStateToProps = state => ({
    isNavOpen: state.mobileNav.leftSidebarOpen,
    isRightNavOpen: state.mobileNav.rightSidebarOpen,
    isConnectWalletOpen: state.mobileNav.connectWalletOpen
});
const mapDispatchToProps = dispatch => ({
    setMobileNavOpen: isOpen => dispatch(setMobileNavOpenStatus(isOpen)),
    setRightMobileNavOpen: isOpen => dispatch(setRightMobileNavOpenStatus(isOpen)),
    setConnectWalletOpen: isOpen => dispatch(setMobileNavConnectWalletOpenStatus(isOpen)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
