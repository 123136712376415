import React from 'react';

import { Container, Row} from "react-bootstrap";


import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/custom.css";
import Footer from "../Components/Footer";

const Discover = () => {

    return (
        <div className="main-container st-discover">
		<div className="container-fluid">
            <div className="row">
           <div className="col-12">
               <div className="d-flex">
                   <span className="stormaLight col-2 text-center text-light radius-15 p-3 mr-3">Creator</span>
                   <span className="stormaLight col-2 text-center text-light radius-15 p-3 mr-3">Collection</span>
                   <span className="stormaLight col-2 text-center text-light radius-15 p-3 mr-3">Seller</span>
                   <span className="stormaLight col-2 text-center text-light radius-15 p-3 mr-3">Collection</span>
               </div>
           </div>
            </div>
            
        </div>
        <Footer />
        </div>
    )
}

export default Discover;
