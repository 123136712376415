import React, { useState } from "react";
import {Modal, Button} from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/custom.css";
import UpcomingOne from "../assets/images/up_1.png";
import userLogo from "../assets/images/user_01.jpg";
import OdinStormaGod from "../assets/images/Odin-StormaGod.jpg";
import user2 from "../assets/images/user_02.jpg";
import FriggStormaGod from "../assets/images/Frigg-StormaGod.jpg";
import LokiStormaGod from "../assets/images/Loki-StormaGod.jpg";
import helaStormaGod from "../assets/images/hela.jpg";
import UpcomingTwo from "../assets/images/up_2.png";
import UpcomingThree from "../assets/images/up_3.jpg";

const Overview = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className="main-container st-overview">
            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <div className="gradiant-bg radius-15 p-5 mb-5">
                        <h2  className="font-35 font-w600">Welcome back User!</h2>
                        <p className="font-18">Check out what you missed while you were away</p>
                    </div>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="bi-card p-3 radius-15">
                                <p className="font-17 mb-4">Latest news</p>
                                <div className="mb-3 border-bottom pb-3">
                                    <p className="font-15 font-weight-bold m-0">NFT Market Sales Begin to Improve After Last Week's Massive Market Slump
                                        <span className="ml-2 d-inline-block c-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="14.25" height="10.5" viewBox="0 0 14.25 10.5"><path id="Vector" d="M10.153,10.271l3.44-3.44a2.255,2.255,0,0,0,0-3.182L10.153.211a.75.75,0,0,0-1.06,1.06l3.219,3.22H.75a.75.75,0,0,0,0,1.5H12.313l-3.22,3.22a.75.75,0,1,0,1.06,1.061Z" fill="#fff"></path></svg></span>
                                    </p>
                                </div>
                                <div className="mb-3 border-bottom pb-3">
                                    <p className="font-15 font-weight-bold m-0">Fortune donates profits from NFT sale to journalism organizations
                                        <span className="ml-2 d-inline-block c-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="14.25" height="10.5" viewBox="0 0 14.25 10.5"><path id="Vector" d="M10.153,10.271l3.44-3.44a2.255,2.255,0,0,0,0-3.182L10.153.211a.75.75,0,0,0-1.06,1.06l3.219,3.22H.75a.75.75,0,0,0,0,1.5H12.313l-3.22,3.22a.75.75,0,1,0,1.06,1.061Z" fill="#fff"></path></svg></span>
                                    </p>
                                </div>
                                <div className="mb-3 border-bottom pb-3">
                                    <p className="font-15 font-weight-bold m-0">The Economic Times
                                        NFTs: Why you should pay attention
                                        <span className="ml-2 d-inline-block c-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="14.25" height="10.5" viewBox="0 0 14.25 10.5"><path id="Vector" d="M10.153,10.271l3.44-3.44a2.255,2.255,0,0,0,0-3.182L10.153.211a.75.75,0,0,0-1.06,1.06l3.219,3.22H.75a.75.75,0,0,0,0,1.5H12.313l-3.22,3.22a.75.75,0,1,0,1.06,1.061Z" fill="#fff"></path></svg></span>
                                    </p>
                                </div>
                                <div className="mb-3 border-bottom pb-3">
                                    <p className="font-15 font-weight-bold m-0">Visa jumps into the NFT craze, buying a 'CryptoPunk' for $150,000
                                        <span className="ml-2 d-inline-block c-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="14.25" height="10.5" viewBox="0 0 14.25 10.5"><path id="Vector" d="M10.153,10.271l3.44-3.44a2.255,2.255,0,0,0,0-3.182L10.153.211a.75.75,0,0,0-1.06,1.06l3.219,3.22H.75a.75.75,0,0,0,0,1.5H12.313l-3.22,3.22a.75.75,0,1,0,1.06,1.061Z" fill="#fff"></path></svg></span>
                                    </p>
                                </div>
                                <div className="mb-3 border-bottom pb-3">
                                    <p className="font-15 font-weight-bold m-0">CryptoPunks blasts past $1 billion in lifetime sales as NFT speculation surges
                                        <span className="ml-2 d-inline-block c-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="14.25" height="10.5" viewBox="0 0 14.25 10.5"><path id="Vector" d="M10.153,10.271l3.44-3.44a2.255,2.255,0,0,0,0-3.182L10.153.211a.75.75,0,0,0-1.06,1.06l3.219,3.22H.75a.75.75,0,0,0,0,1.5H12.313l-3.22,3.22a.75.75,0,1,0,1.06,1.061Z" fill="#fff"></path></svg></span>
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="bi-card p-4 radius-15">
                                <div className="d-flex mb-4">
                                    <p className="font-17 font-w400 m-0">Viewer Statistics</p>
                                    <div className="ml-auto">yesterday</div>
                                </div>
                                <div className="day-calendar d-flex justify-content-around">
                                    <div>
                                        <p className="font-14 mb-4">Mon</p>
                                        <div className="v-line">
                                            <span style={{height: "60%"}}></span>
                                            <span className="v-line-org" style={{height: "20%"}}></span>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="font-14 mb-4">Tue</p>
                                        <div className="v-line">
                                            <span style={{height: "100%"}}></span>
                                            <span className="v-line-org" style={{height: "90%"}}></span>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="font-14 mb-4">Wed</p>
                                        <div className="v-line">
                                            <span style={{height: "90%"}}></span>
                                            <span className="v-line-org" style={{height: "40%"}}></span>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="font-14 mb-4">Thu</p>
                                        <div className="v-line">
                                            <span style={{height: "100%"}}></span>
                                            <span className="v-line-org" style={{height: "0%"}}></span>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="font-14 mb-4">Fri</p>
                                        <div className="v-line">
                                            <span style={{height: "60%"}}></span>
                                            <span className="v-line-org" style={{height: "40%"}}></span>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="font-14 mb-4">Sat</p>
                                        <div className="v-line">
                                            <span style={{height: "80%"}}></span>
                                            <span className="v-line-org" style={{height: "0%"}}></span>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="font-14 mb-4">Sun</p>
                                        <div className="v-line">
                                            <span style={{height: "100%"}}></span>
                                            <span className="v-line-org" style={{height: "50%"}}></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap mt-4 pl-4">
                                    <div className="d-flex align-items-center mr-5"><span className="cal-circle mr-2 collector"></span>Collectors</div>
                                    <div className="d-flex align-items-center mr-5"><span className="cal-circle mr-2 creators"></span>Creators</div>
                                    <div className="d-flex align-items-center"><span className="cal-circle mr-2 investors"></span>Investors</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-4">
                            <div className="bi-card text-light rounded-pill pr-3">
                                <div className="d-flex align-items-center">
                                    <div className="bi-card w-90px h-90px rounded-circle d-flex align-items-center justify-content-center">
                         <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="49.875" height="47.25" viewBox="0 0 49.875 47.25">
  <g id="Group_2261" data-name="Group 2261" transform="translate(-7.875 -7.875)">
    <path id="Path_3890" data-name="Path 3890" d="M50.25,14.235V8.25A5.265,5.265,0,0,0,45,3H8.25A5.249,5.249,0,0,0,3,8.25V45a5.249,5.249,0,0,0,5.25,5.25H45A5.265,5.265,0,0,0,50.25,45V39.015A5.252,5.252,0,0,0,52.875,34.5V18.75A5.252,5.252,0,0,0,50.25,14.235ZM47.625,18.75V34.5H29.25V18.75ZM8.25,45V8.25H45V13.5H29.25A5.265,5.265,0,0,0,24,18.75V34.5a5.265,5.265,0,0,0,5.25,5.25H45V45Z" transform="translate(4.875 4.875)" fill="#2fecff"/>
    <circle id="Ellipse_17689" data-name="Ellipse 17689" cx="3.938" cy="3.938" r="3.938" transform="translate(38.063 27.563)" fill="#2fecff"/>
  </g>
</svg>
                         </span>
                                    </div>
                                    <div className="px-3">
                                        <p className="mb-0 head font-17 font-weight-bold">My Wallet</p>
                                    </div>
                                    <span className="ml-auto d-inline-block c-arrow">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="14.25" height="10.5" viewBox="0 0 14.25 10.5"><path id="Vector" d="M10.153,10.271l3.44-3.44a2.255,2.255,0,0,0,0-3.182L10.153.211a.75.75,0,0,0-1.06,1.06l3.219,3.22H.75a.75.75,0,0,0,0,1.5H12.313l-3.22,3.22a.75.75,0,1,0,1.06,1.061Z" fill="#fff"></path></svg>
                                    </span>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="bi-card text-light rounded-pill pr-3">
                                <div className="d-flex align-items-center">
                                    <div className="bi-card w-90px h-90px rounded-circle d-flex align-items-center justify-content-center">
                         <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="60.783" height="52.1" viewBox="0 0 60.783 52.1">
  <g id="Group_2262" data-name="Group 2262" transform="translate(-2.894 -8.683)">
    <path id="Path_3892" data-name="Path 3892" d="M35.733,3A26.052,26.052,0,0,0,9.683,29.05H1L12.259,40.309l.2.405L24.156,29.05H15.472a20.358,20.358,0,1,1,5.963,14.3l-4.11,4.11A26.043,26.043,0,1,0,35.733,3ZM32.839,17.472V31.944l12.3,7.294,2.229-3.7L37.181,29.484V17.472Z" transform="translate(1.894 5.683)" fill="#2fecff"/>
  </g>
</svg>

                         </span>
                                    </div>
                                    <div className="px-3">
                                        <p className="mb-0 head font-17 font-weight-bold">My Wallet</p>
                                    </div>
                                    <span className="ml-auto d-inline-block c-arrow">
                                         <svg xmlns="http://www.w3.org/2000/svg" width="14.25" height="10.5" viewBox="0 0 14.25 10.5"><path id="Vector" d="M10.153,10.271l3.44-3.44a2.255,2.255,0,0,0,0-3.182L10.153.211a.75.75,0,0,0-1.06,1.06l3.219,3.22H.75a.75.75,0,0,0,0,1.5H12.313l-3.22,3.22a.75.75,0,1,0,1.06,1.061Z" fill="#fff"></path></svg>
                                    </span>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="bi-card text-light rounded-pill pr-3">
                                <div className="d-flex align-items-center">
                                    <div className="bi-card w-90px h-90px rounded-circle d-flex align-items-center justify-content-center">
                         <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="56.028" height="56.028" viewBox="0 0 56.028 56.028">
  <g id="Group_2263" data-name="Group 2263" transform="translate(-5.603 -5.603)">
    <path id="Path_3894" data-name="Path 3894" d="M27.213,46.822h5.6v-5.6h-5.6ZM30.014,2A28.014,28.014,0,1,0,58.028,30.014,28.024,28.024,0,0,0,30.014,2Zm0,50.425A22.411,22.411,0,1,1,52.425,30.014,22.441,22.441,0,0,1,30.014,52.425Zm0-39.219A11.2,11.2,0,0,0,18.808,24.411h5.6a5.6,5.6,0,0,1,11.206,0c0,5.6-8.4,4.9-8.4,14.007h5.6c0-6.3,8.4-7,8.4-14.007A11.2,11.2,0,0,0,30.014,13.206Z" transform="translate(3.603 3.603)" fill="#2fecff"/>
  </g>
</svg>

                         </span>
                                    </div>
                                    <div className="px-3">
                                        <p className="mb-0 head font-17 font-weight-bold">My Wallet</p>
                                    </div>
                                    <span className="ml-auto d-inline-block c-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.25" height="10.5" viewBox="0 0 14.25 10.5"><path id="Vector" d="M10.153,10.271l3.44-3.44a2.255,2.255,0,0,0,0-3.182L10.153.211a.75.75,0,0,0-1.06,1.06l3.219,3.22H.75a.75.75,0,0,0,0,1.5H12.313l-3.22,3.22a.75.75,0,1,0,1.06,1.061Z" fill="#fff"></path></svg>
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}


export default Overview;
