import React, {useState} from "react";
import PolygonIcon from "../../assets/images/Polygon.svg"
import DollarIcon from "../../assets/images/doller.png"
import WalletIcon from "../../assets/images/wal.svg"
import {setMobileNavConnectWalletOpenStatus} from "../../redux/mobile-nav/mobile-nav-actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Nav} from "react-bootstrap";

const TopActions = ({isConnectWalletOpen, setConnectWalletOpen}) => {
    const [open, setOpen] = useState(false);
    const toggleConnectWallet = () => {
        setConnectWalletOpen(!isConnectWalletOpen);
    }
    return (
        <div className="p-2 px-2 mobile-hlist position-relative d-flex align-items-center">
                    <span className="d-block" onClick={()=> setOpen(!open)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="none" stroke="currentColor"
                             stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                             className="feather feather-more-vertical"><circle cx="12" cy="12"
                                                                               r="1"></circle><circle
                            cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                    </span>
            <div className={`top-header-list  bi-badge p-3 ${open ? 'open' : ''}`}>
                <div className="badge-in d-flex align-items-center text-light mb-3">
                    <a href="#" className="btn btn-primary bi-border rounded-pill mh-52 p-2 px-4">Buy STMA</a>
                </div>
                <div className="badge-in d-flex align-items-center text-light mb-3">
                    <Nav.Link as={Link} to="/login" className="text-light d-flex align-items-center">
                        Sign In
                        <span className="ml-2">
                   <svg xmlns="http://www.w3.org/2000/svg" width="14.25" height="10.5" viewBox="0 0 14.25 10.5">
  <path id="Vector" d="M10.153,10.271l3.44-3.44a2.255,2.255,0,0,0,0-3.182L10.153.211a.75.75,0,0,0-1.06,1.06l3.219,3.22H.75a.75.75,0,0,0,0,1.5H12.313l-3.22,3.22a.75.75,0,1,0,1.06,1.061Z" fill="#2fecff"/>
</svg>
                   </span>
                    </Nav.Link>
                </div>

            </div>
        </div>
    );
}
const mapStateToProps = state => ({

    isConnectWalletOpen: state.mobileNav.connectWalletOpen
});
const mapDispatchToProps = dispatch => ({
    setConnectWalletOpen: isOpen => dispatch(setMobileNavConnectWalletOpenStatus(isOpen)),
});
export default connect(mapStateToProps, mapDispatchToProps) (TopActions);
