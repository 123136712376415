import React from 'react';

import { Container, Row} from "react-bootstrap";


import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/custom.css";
import Footer from "../Components/Footer";

const Activity = () => {

    return (
        <div className="main-container st-discover">
		<div className="container-fluid">
            <div className="row">
           <div className="col-12">
               <div className="d-flex flex-wrap">
                   <span className="stormaLight col-auto text-center text-light radius-15 p-3 mr-3">Creator</span>
                   <span className="stormaLight col-auto text-center text-light radius-15 p-3 mr-3">Collection</span>
                   <span className="stormaLight col-auto text-center text-light radius-15 p-3 mr-3">Buyers</span>
                   <span className="stormaLight col-auto text-center text-light radius-15 p-3 mr-3">Sellers</span>
                   <span className="stormaLight col-auto text-center text-light radius-15 p-3 mr-3">Artwork</span>
                   <span className="stormaLight col-auto text-center text-light radius-15 p-3 mr-3">Bids</span>

               </div>
           </div>
            </div>
            
        </div>
        <Footer />
        </div>
    )
}

export default Activity;
