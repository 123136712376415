import React, { useState } from "react";
import {Modal, Button} from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/custom.css";
import BLogo from "../assets/images/r-user.png"

const Settings = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className="main-container st-setting">
            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <div className="bi-profile">
                        <div className="bi-userimg my-4 position-relative border-0">
                            <img src={BLogo}/>
                            <a href="#" className="position-absolute user-edit">
                                <svg xmlns="http://www.w3.org/2000/svg" width="11.676" height="11.676"
                                     viewBox="0 0 11.676 11.676">
                                    <path id="Path_2343" data-name="Path 2343"
                                          d="M3,12.242v2.432H5.432L12.606,7.5,10.173,5.068ZM14.487,5.619a.646.646,0,0,0,0-.915L12.969,3.187a.646.646,0,0,0-.915,0L10.867,4.374,13.3,6.806l1.187-1.187Z"
                                          transform="translate(-3 -2.997)" fill="#0f1128"/>
                                </svg>
                            </a>
                        </div>
                    </div>
<div className="text-center">
    <p className="font-20 text-info font-weight-bold mb-2">Storma</p>
    <p className="font-16">Platinum</p>
</div>

           <div className="stormaLight radius-15 p-4 px-5 mt-4">
               <div className="row">
                   <div className="col-md-3">
                       <div className="d-flex align-items-center font-17">
                           Username <span className="ml-auto">
                           <svg xmlns="http://www.w3.org/2000/svg" width="15.26" height="15.304" viewBox="0 0 15.26 15.304">
  <g id="Group_553" data-name="Group 553" transform="translate(-384.975 -261.395)">
    <path id="Path_675" data-name="Path 675" d="M384.983,287.136c0-1.01-.024-1.889.015-2.765a1.21,1.21,0,0,1,.318-.734c2.985-3.01,5.987-6,8.931-8.946l3.136,3.2c-.115.121-.365.4-.628.66-2.548,2.551-5.124,5.076-7.631,7.666a2.561,2.561,0,0,1-2.309.936A16.335,16.335,0,0,0,384.983,287.136Zm10.235-9.442-.821-.716c-2.535,2.525-5.034,5-7.507,7.506-.179.181-.169.549-.311,1.062.52-.165.912-.172,1.115-.371C390.181,282.734,392.641,280.266,395.218,277.694Z" transform="translate(0 -10.471)" fill="#2fecff"/>
    <path id="Path_676" data-name="Path 676" d="M437,266.382l-3.372-3.4,2.252-1.583,2.672,2.621Z" transform="translate(-38.317)" fill="#2fecff"/>
  </g>
</svg>
                       </span>
                       </div>
                       <div className="setting-row mb-3">
                           <input type="text" className="form-control bg-transparent border-0 p-0" placeholder="User"/>
                       </div>
                       <div className="d-flex align-items-center font-17">
                           Email <span className="ml-auto">
                           <svg xmlns="http://www.w3.org/2000/svg" width="15.26" height="15.304" viewBox="0 0 15.26 15.304">
  <g id="Group_553" data-name="Group 553" transform="translate(-384.975 -261.395)">
    <path id="Path_675" data-name="Path 675" d="M384.983,287.136c0-1.01-.024-1.889.015-2.765a1.21,1.21,0,0,1,.318-.734c2.985-3.01,5.987-6,8.931-8.946l3.136,3.2c-.115.121-.365.4-.628.66-2.548,2.551-5.124,5.076-7.631,7.666a2.561,2.561,0,0,1-2.309.936A16.335,16.335,0,0,0,384.983,287.136Zm10.235-9.442-.821-.716c-2.535,2.525-5.034,5-7.507,7.506-.179.181-.169.549-.311,1.062.52-.165.912-.172,1.115-.371C390.181,282.734,392.641,280.266,395.218,277.694Z" transform="translate(0 -10.471)" fill="#2fecff"/>
    <path id="Path_676" data-name="Path 676" d="M437,266.382l-3.372-3.4,2.252-1.583,2.672,2.621Z" transform="translate(-38.317)" fill="#2fecff"/>
  </g>
</svg>
                       </span>
                       </div>
                       <div className="setting-row">
                           <input type="text" className="form-control bg-transparent border-0 p-0" placeholder="user@gmail.com"/>
                       </div>
                   </div>
                   <div className="col-md-3 ml-auto">

                       <div className="d-flex align-items-center font-17">
                           Password <span className="ml-auto">
                           <svg xmlns="http://www.w3.org/2000/svg" width="15.26" height="15.304" viewBox="0 0 15.26 15.304">
  <g id="Group_553" data-name="Group 553" transform="translate(-384.975 -261.395)">
    <path id="Path_675" data-name="Path 675" d="M384.983,287.136c0-1.01-.024-1.889.015-2.765a1.21,1.21,0,0,1,.318-.734c2.985-3.01,5.987-6,8.931-8.946l3.136,3.2c-.115.121-.365.4-.628.66-2.548,2.551-5.124,5.076-7.631,7.666a2.561,2.561,0,0,1-2.309.936A16.335,16.335,0,0,0,384.983,287.136Zm10.235-9.442-.821-.716c-2.535,2.525-5.034,5-7.507,7.506-.179.181-.169.549-.311,1.062.52-.165.912-.172,1.115-.371C390.181,282.734,392.641,280.266,395.218,277.694Z" transform="translate(0 -10.471)" fill="#2fecff"/>
    <path id="Path_676" data-name="Path 676" d="M437,266.382l-3.372-3.4,2.252-1.583,2.672,2.621Z" transform="translate(-38.317)" fill="#2fecff"/>
  </g>
</svg>
                       </span>
                       </div>
                       <div className="setting-row mb-3">
                           <input type="Password" className="form-control bg-transparent border-0 p-0" placeholder="Password"/>
                       </div>
                   </div>
               </div>
           </div>
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <div className="stormaLight radius-15 p-4 px-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="d-flex align-items-center font-17">
                                            Currency <span className="ml-auto">
                           <svg xmlns="http://www.w3.org/2000/svg" width="15.26" height="15.304" viewBox="0 0 15.26 15.304">
  <g id="Group_553" data-name="Group 553" transform="translate(-384.975 -261.395)">
    <path id="Path_675" data-name="Path 675" d="M384.983,287.136c0-1.01-.024-1.889.015-2.765a1.21,1.21,0,0,1,.318-.734c2.985-3.01,5.987-6,8.931-8.946l3.136,3.2c-.115.121-.365.4-.628.66-2.548,2.551-5.124,5.076-7.631,7.666a2.561,2.561,0,0,1-2.309.936A16.335,16.335,0,0,0,384.983,287.136Zm10.235-9.442-.821-.716c-2.535,2.525-5.034,5-7.507,7.506-.179.181-.169.549-.311,1.062.52-.165.912-.172,1.115-.371C390.181,282.734,392.641,280.266,395.218,277.694Z" transform="translate(0 -10.471)" fill="#2fecff"/>
    <path id="Path_676" data-name="Path 676" d="M437,266.382l-3.372-3.4,2.252-1.583,2.672,2.621Z" transform="translate(-38.317)" fill="#2fecff"/>
  </g>
</svg>
                       </span>
                                        </div>
                                        <div className="setting-row mb-3">
                                            <input type="text" className="form-control bg-transparent border-0 p-0" placeholder="USD"/>
                                        </div>
                                        <div className="d-flex align-items-center font-17">
                                            CryptoCurrency <span className="ml-auto">
                           <svg xmlns="http://www.w3.org/2000/svg" width="15.26" height="15.304" viewBox="0 0 15.26 15.304">
  <g id="Group_553" data-name="Group 553" transform="translate(-384.975 -261.395)">
    <path id="Path_675" data-name="Path 675" d="M384.983,287.136c0-1.01-.024-1.889.015-2.765a1.21,1.21,0,0,1,.318-.734c2.985-3.01,5.987-6,8.931-8.946l3.136,3.2c-.115.121-.365.4-.628.66-2.548,2.551-5.124,5.076-7.631,7.666a2.561,2.561,0,0,1-2.309.936A16.335,16.335,0,0,0,384.983,287.136Zm10.235-9.442-.821-.716c-2.535,2.525-5.034,5-7.507,7.506-.179.181-.169.549-.311,1.062.52-.165.912-.172,1.115-.371C390.181,282.734,392.641,280.266,395.218,277.694Z" transform="translate(0 -10.471)" fill="#2fecff"/>
    <path id="Path_676" data-name="Path 676" d="M437,266.382l-3.372-3.4,2.252-1.583,2.672,2.621Z" transform="translate(-38.317)" fill="#2fecff"/>
  </g>
</svg>
                       </span>
                                        </div>
                                        <div className="setting-row">
                                            <input type="text" className="form-control bg-transparent border-0 p-0" placeholder="CryptosX"/>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 st-setting-last">
                            <div className="stormaLight radius-15 p-4 px-5">
                                <div className="row">
                                    <div className="col-auto">
                                        <div className="d-flex align-items-center font-17">
                                            Card Number
                                        </div>
                                        <div className="setting-row mb-3">
                                            <input type="text" className="form-control bg-transparent border-0 p-0" placeholder="1234 5678 1234 5678"/>
                                        </div>
                                        <div className="d-flex align-items-center font-17">
                                            Expiration Date

                                        </div>
                                        <div className="setting-row">
                                            <input type="text" className="form-control bg-transparent border-0 p-0" placeholder="01/22"/>
                                        </div>
                                    </div>
                                    <div className="col-md-3 ml-auto">
                                        <div className="d-flex align-items-center font-17">
                                            CVC
                                        </div>
                                        <div className="setting-row mb-3">
                                            <input type="text" className="form-control bg-transparent border-0 p-0" placeholder="Password"/>
                                        </div>
                                        <div className="d-flex">
                                        <span className="ml-auto">
                           <svg xmlns="http://www.w3.org/2000/svg" width="15.26" height="15.304" viewBox="0 0 15.26 15.304">
  <g id="Group_553" data-name="Group 553" transform="translate(-384.975 -261.395)">
    <path id="Path_675" data-name="Path 675" d="M384.983,287.136c0-1.01-.024-1.889.015-2.765a1.21,1.21,0,0,1,.318-.734c2.985-3.01,5.987-6,8.931-8.946l3.136,3.2c-.115.121-.365.4-.628.66-2.548,2.551-5.124,5.076-7.631,7.666a2.561,2.561,0,0,1-2.309.936A16.335,16.335,0,0,0,384.983,287.136Zm10.235-9.442-.821-.716c-2.535,2.525-5.034,5-7.507,7.506-.179.181-.169.549-.311,1.062.52-.165.912-.172,1.115-.371C390.181,282.734,392.641,280.266,395.218,277.694Z" transform="translate(0 -10.471)" fill="#2fecff"/>
    <path id="Path_676" data-name="Path 676" d="M437,266.382l-3.372-3.4,2.252-1.583,2.672,2.621Z" transform="translate(-38.317)" fill="#2fecff"/>
  </g>
</svg>
                       </span>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}


export default Settings;
