import React, { useState } from "react";
import {Modal, Button} from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/custom.css";

const Login = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className="main-container st-login">
            <div className="row justify-content-center">
                <div className="col-md-9">
                    <div className="stormaLight radius-15 p-5 mb-5">
                        <div className="p-4">
                        <div className="mb-4">
                            <p className="font-18 mb-4">Sign In</p>
                            <input type="text" className="form-control h-45 font-14 radius-6 bg-theme border-0"
                                   placeholder="Email Address"/></div>
                        <div className="mb-4">
                            <input type="text" className="form-control h-45 font-14 radius-6 bg-theme border-0"
                                   placeholder="Password"/></div>
                            <p className="font-15">By using our website, you agree to our Terms of Use and Privacy Policy. You also agree to receive periodic newsletters from us.</p>
                            <div className="d-flex">
                                <a className="btn d-flex align-items-center text-light font-15 ml-auto">Sign In <span className="ml-2"><svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                                           width="14.25" height="10.5"
                                                                                                                                           viewBox="0 0 14.25 10.5"><path
                                    id="Vector"
                                    d="M10.153,10.271l3.44-3.44a2.255,2.255,0,0,0,0-3.182L10.153.211a.75.75,0,0,0-1.06,1.06l3.219,3.22H.75a.75.75,0,0,0,0,1.5H12.313l-3.22,3.22a.75.75,0,1,0,1.06,1.061Z"
                                    fill="#2fecff"></path></svg></span></a>
                            </div>
                            <div className="d-flex">
                                <a className="btn d-flex align-items-center text-light font-15 ml-auto">Don't have an account? Connect Wallet
                                    <span className="ml-2"><svg xmlns="http://www.w3.org/2000/svg"
                                                                width="14.25" height="10.5"
                                                                viewBox="0 0 14.25 10.5"><path
                                        id="Vector"
                                        d="M10.153,10.271l3.44-3.44a2.255,2.255,0,0,0,0-3.182L10.153.211a.75.75,0,0,0-1.06,1.06l3.219,3.22H.75a.75.75,0,0,0,0,1.5H12.313l-3.22,3.22a.75.75,0,1,0,1.06,1.061Z"
                                        fill="#2fecff"></path></svg></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}


export default Login;
