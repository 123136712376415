import React, { useState } from "react";
import {Modal, Button} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/custom.css";
import LokiStormaGod from "../assets/images/Loki-StormaGod.jpg";
import Chart from "../assets/images/charts.png";
import BLogo from "../assets/images/r-user.png";


const ProductLoki = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className="main-container st-product">
            <div className="row">
                <div className="col-md-6">
                    <div className="position-relative mb-3">
                        <div className="share position-absolute right-15 top-15 d-flex">
                            <a href="#" className="stormaBblack d-flex justify-content-center icon-h">
                                <svg id="Group_108" data-name="Group 108" xmlns="http://www.w3.org/2000/svg" width="24.502" height="30.038" viewBox="0 0 24.502 30.038">
  <path id="Path_62" data-name="Path 62" d="M401.456,263.727v5.093l-6.839-6.883,6.762-6.781v5.23c6.894.561,13.1,5.383,12.231,13.989a12.252,12.252,0,0,1-24.421-1.336H392.1a9.606,9.606,0,0,0,4.342,7.6,8.873,8.873,0,0,0,6.423,1.371,9.2,9.2,0,0,0-1.411-18.285Z" transform="translate(-389.189 -255.156)" fill="#fff"/>
</svg>
                            </a>
                            <a href="#" className="stormaBblack d-flex justify-content-center icon-h">
                                <svg id="Group_107" data-name="Group 107" xmlns="http://www.w3.org/2000/svg" width="24.502" height="27.146" viewBox="0 0 24.502 27.146">
  <path id="Path_61" data-name="Path 61" d="M385.685,271.416a4.061,4.061,0,0,1,5.814-3.726,2.339,2.339,0,0,0,2.472-.164q3.239-1.963,6.546-3.816a2.344,2.344,0,0,0,1.382-2.081,4.036,4.036,0,0,1,5.01-3.67,4.209,4.209,0,0,1,3.119,5.3,4.064,4.064,0,0,1-5.506,2.641,2.741,2.741,0,0,0-2.93.254c-2.156,1.315-4.369,2.535-6.531,3.841-1.506.91-1.526,2.046-.038,2.941,2.453,1.475,4.954,2.87,7.414,4.334a1.7,1.7,0,0,0,1.718.044c2.559-1.059,4.9-.061,5.713,2.359a4.042,4.042,0,0,1-2.934,5.209c-2.359.615-4.589-.973-4.994-3.7a2.279,2.279,0,0,0-1.25-1.823c-2.289-1.312-4.579-2.623-6.834-3.99a2.2,2.2,0,0,0-2.322-.106A4.073,4.073,0,0,1,385.685,271.416Z" transform="translate(-385.685 -257.872)" fill="#fff"/>
</svg>

                            </a>
                            <a href="#" className="stormaBblack  d-flex justify-content-center icon-h">
                                <svg id="Group_106" data-name="Group 106" xmlns="http://www.w3.org/2000/svg" width="6.982" height="27.146" viewBox="0 0 6.982 27.146">
  <path id="Path_58" data-name="Path 58" d="M417.213,265.975a3.488,3.488,0,1,1-3.516,3.355A3.4,3.4,0,0,1,417.213,265.975Z" transform="translate(-413.694 -265.975)" fill="#fff"/>
  <path id="Path_59" data-name="Path 59" d="M417.279,289.973a3.489,3.489,0,1,1-3.582,3.288A3.406,3.406,0,0,1,417.279,289.973Z" transform="translate(-413.692 -279.889)" fill="#fff"/>
  <path id="Path_60" data-name="Path 60" d="M417.14,313.975a3.488,3.488,0,1,1-3.44,3.431A3.4,3.4,0,0,1,417.14,313.975Z" transform="translate(-413.696 -293.805)" fill="#fff"/>
</svg>

                            </a>
                        </div>
                    <img className="w-100" src={LokiStormaGod}/>
                </div>
                    <div className="stormaLight radius-15 p-4 mb-3">
                        <div className="d-flex align-items-center mb-3">
                            <span className="mr-2">
                               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" className="feather feather-align-right">
                                   <line x1="21" y1="10" x2="7" y2="10"/><line
                                   x1="21" y1="6" x2="3" y2="6"/><line x1="21" y1="14" x2="3" y2="14"/>
                                   <line x1="21" y1="18" x2="7" y2="18"/></svg>
                            </span><p className="font-17 m-0">Description</p>
                        </div>
                        <p className="font-14">StormaGods is 888 generative collectibles, conjured by, wait for it, the third largest STO exchange in the world; CryptoSX.</p>
                        <p className="font-14">The idea is that every universe has a sovereign power ruling over it, albeit what you call them - deities, gods, supremes, etc. Ever since the rise of decentralised platforms, most of the market underestimates the power of the STO: Security Token Offering. Tainted with the reputation of being too centralised and too conservative, yet STORMAGods is here to redefine that perception. Did you know that the largest coin offering in history, albeit ICO, STO, IEO, or IDO is in fact a Gold-based STO called DignityGold? And it’s only on CryptoSX.</p>
                        <p className="text-info font-14 m-0">
                            www.stormagods.com
                        </p>
                    </div>
                    <div className="stormaLight radius-15 p-4 mb-3">
                        <div className="d-flex align-items-center mb-3">
                            <span className="mr-2">
                               <svg xmlns="http://www.w3.org/2000/svg" width="25.202" height="19.144" viewBox="0 0 25.202 19.144">
  <path id="Path_677" data-name="Path 677" d="M25.2,1.173v16.8a3.3,3.3,0,0,1-2.765,1.17q-7.19-.066-14.381,0a3.14,3.14,0,0,1-2.922-1.578C3.454,14.969,1.715,12.41,0,9.836V9.311C1.715,6.735,3.457,4.176,5.132,1.574A3.106,3.106,0,0,1,8.048,0c4.8.054,9.592.046,14.388,0A3.3,3.3,0,0,1,25.2,1.173ZM9.5,10.96A1.394,1.394,0,0,0,10.8,9.6,1.273,1.273,0,0,0,9.512,8.226,1.277,1.277,0,0,0,8.1,9.61C8.112,10.406,8.666,10.84,9.5,10.96Zm5.211,0c.786-.127,1.341-.56,1.34-1.36a1.278,1.278,0,0,0-1.423-1.37,1.273,1.273,0,0,0-1.276,1.39A1.38,1.38,0,0,0,14.71,10.955Zm6.623-1.395c-.127-.788-.565-1.34-1.364-1.337A1.278,1.278,0,0,0,18.6,9.653,1.273,1.273,0,0,0,20,10.925,1.378,1.378,0,0,0,21.333,9.561Z" transform="translate(25.202 19.144) rotate(180)" fill="#fff"/>
</svg>
                            </span><p className="font-17 m-0">Properties</p>
                        </div>
                        <p className="font-14 mb-0">Created By Storma<br/>
                            3000 x 3000 px, PNG (3.1 MB)<br/>
                            This is a signed and limited edition digital creation.</p>
                    </div>
                    <div className="stormaLight radius-15 p-4 mb-3">
                        <div className="d-flex align-items-center mb-3">
                            <span className="mr-2">
                              <svg id="Group_572" data-name="Group 572" xmlns="http://www.w3.org/2000/svg" width="17.083" height="18.029" viewBox="0 0 17.083 18.029">
  <path id="Path_78" data-name="Path 78" d="M385.226,310.617v-7.08H392.3v7.08Z" transform="translate(-385.226 -293.109)" fill="#fff"/>
  <path id="Path_79" data-name="Path 79" d="M429.068,309.414a4.052,4.052,0,1,1,4.07-3.976A4.075,4.075,0,0,1,429.068,309.414Z" transform="translate(-416.056 -291.385)" fill="#fff"/>
  <path id="Path_80" data-name="Path 80" d="M399.176,265.356,404.1,257.3l4.921,8.052Z" transform="translate(-396.03 -257.304)" fill="#fff"/>
</svg>
                            </span><p className="font-17 m-0">Details</p>
                        </div>
                        <p className="font-14 d-flex">Contract Address <span className="ml-auto">0x495f...7b5e</span></p>
                        <p className="font-14 d-flex">Token ID <span className="ml-auto">33271220540992826225097820539128178</span></p>
                        <p className="font-14 d-flex">Blockchain <span className="ml-auto">Cryptos</span></p>
                        <p className="font-14 mb-0 d-flex">Metadata <span className="ml-auto">Editable</span></p>
                    </div>
                    <div className="stormaLight radius-15 p-4 mb-3">
                        <div className="d-flex align-items-center mb-3">
                            <span className="mr-2">
                               <svg id="Group_582" data-name="Group 582" xmlns="http://www.w3.org/2000/svg" width="21.162" height="18.5" viewBox="0 0 21.162 18.5">
  <path id="Path_81" data-name="Path 81" d="M384.2,276.96l1.443-1.237c3.537,2.518,6.944,2.609,9.711.233A7.19,7.19,0,0,0,389.2,263.45a7.1,7.1,0,0,0-5.691,6.958h3.586l-4.719,3.919-3.6-3.778h2.573a9.709,9.709,0,0,1,2.867-6.692,9.256,9.256,0,1,1,10.9,14.744A9.031,9.031,0,0,1,384.2,276.96Z" transform="translate(-378.776 -261.234)" fill="#fff"/>
  <path id="Path_82" data-name="Path 82" d="M426.184,288l-.745,1.217c-1.347-.8-2.672-1.549-3.959-2.366a1.154,1.154,0,0,1-.413-.834c-.041-1.451-.019-2.9-.019-4.445h1.544c0,1.086.046,2.189-.016,3.287a1.411,1.411,0,0,0,.868,1.52C424.354,286.846,425.211,287.417,426.184,288Z" transform="translate(-410.182 -276.347)" fill="#fff"/>
</svg>
                            </span><p className="font-17 m-0">Trading History</p>
                        </div>
                        <div className="mb-3">
                            <select className="form-control h-45 font-14 radius-6 text-light bg-theme border-0">
                                <option>Filter</option>
                                <option>Filter</option>
                                <option>Filter</option>
                            </select>
                           </div>
                        <div className="">
                            <table className="table table-borderless text-light mb-0">



                                <tbody>
                                <tr className="no-border">
                                    <td className="font-17">Event</td>
                                    <td className="font-17">Price</td>
                                    <td className="font-17">From</td>
                                    <td className="font-17">To</td>
                                    <td className="font-17">Date</td>
                                </tr>
                                <tr className="no-border">
                                    <td className="font-14">Listed</td>
                                    <td className="font-14"> <img className="mr-3 wh-30" src={BLogo}/>3</td>
                                    <td className="font-14">CryptKeeper</td>
                                    <td className="font-14">@mdo</td>
                                    <td className="font-14">@mdo</td>
                                </tr>
                                <tr className="no-border">
                                    <td className="font-14">Created</td>
                                    <td className="font-14"></td>
                                    <td className="font-14">WCAR</td>
                                    <td className="font-14">CryptKeeper</td>
                                    <td className="font-14">3 Months Ago</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="stormaLight radius-15 p-4 mb-3">
                        <h3 className="font-25 font-w600 mb-4">Loki StormaGod</h3>
                        <div className="d-flex align-items-center">
                            <div>
                            <p className="font-17 m-0">Owned by</p>
                            <p className="font-14 m-0">Storma</p>
                            </div>
                            <div className="ml-auto d-flex font-14">
                                <span className="mr-2"><svg id="Group_564" data-name="Group 564" xmlns="http://www.w3.org/2000/svg" width="19.65" height="13.733" viewBox="0 0 19.65 13.733">
  <path id="Path_74" data-name="Path 74" d="M387.824,267.122c4.416.226,7.707,2.141,9.716,6.044a2.078,2.078,0,0,1,.032,1.651,10.761,10.761,0,0,1-19.333.012,2.08,2.08,0,0,1,.02-1.651A10.717,10.717,0,0,1,387.824,267.122Zm.065,2.325a4.577,4.577,0,1,0,4.589,4.563A4.588,4.588,0,0,0,387.889,269.447Z" transform="translate(-378.079 -267.122)" fill="#fff"/>
  <path id="Path_75" data-name="Path 75" d="M414.512,288.108a2.738,2.738,0,1,1-2.71-2.793A2.765,2.765,0,0,1,414.512,288.108Z" transform="translate(-401.951 -281.151)" fill="#fff"/>
</svg></span>
                                25 views</div>
                        </div>
                    </div>
                    <div className="stormaLight radius-15 p-4 mb-3">

                        <div className="d-flex align-items-center">
                            <div>
                                <p className="font-14 mb-2">Current Price</p>
                                <p className="font-17 m-0"><img className="mr-1 wh-30" src={BLogo}/> 19 ($264.89)</p>

                            </div>
                            <div className="ml-auto">
                                <a href="" className="btn btn-primary light font-weight-light p-2 px-3 radius-6 font-16">Buy Now</a>
                            </div>
                        </div>
                    </div>
                    <div className="stormaLight radius-15 p-4 mb-3">
                        <div className="d-flex align-items-center mb-3">
                            <span className="mr-2">
                               <svg id="Group_579" data-name="Group 579" xmlns="http://www.w3.org/2000/svg" width="23.493" height="12.888" viewBox="0 0 23.493 12.888">
  <path id="Path_69" data-name="Path 69" d="M392.648,292.751a2.1,2.1,0,0,1-2.2-1.877c-.245-1.644-1.612-2.344-2.666-3.284-.429-.383-1.005-.082-1.416.331-1.246,1.253-2.491,2.508-3.748,3.749a2.188,2.188,0,0,0-.544,1.305,2.2,2.2,0,0,1-4.373-.164,2.083,2.083,0,0,1,1.876-2.317,2.753,2.753,0,0,0,1.683-.925q1.255-1.275,2.533-2.528a4.055,4.055,0,0,0,1.44-2.421,1.91,1.91,0,0,1,2.145-1.582,1.932,1.932,0,0,1,2.07,1.689c.2,1.689,1.577,2.365,2.561,3.373a.986.986,0,0,0,1.436-.138c1.009-.99,2-2,3-3a1.887,1.887,0,0,0,.363-1.106,2.205,2.205,0,1,1,2.508,2.51c-1.476.313-4.149,3.039-4.507,4.574A2.168,2.168,0,0,1,392.648,292.751Z" transform="translate(-377.696 -281.971)" fill="#fff"/>
</svg>

                            </span><p className="font-17 m-0">Price History</p>
                            <div className="ml-auto">yesterday</div>
                        </div>
                        <div className="">
                            <img className="w-100" src={Chart}/>

                        </div>
                    </div>
                    <div className="stormaLight radius-15 p-4 mb-3">
                        <div className="d-flex align-items-center mb-3">
                            <span className="mr-2">
                               <svg xmlns="http://www.w3.org/2000/svg" width="27.099" height="27.099" viewBox="0 0 27.099 27.099">
  <g id="Group_599" data-name="Group 599" transform="translate(9.572) rotate(45)">
    <g id="Group_113" data-name="Group 113" transform="translate(0 0)">
      <path id="Path_76" data-name="Path 76" d="M24.759,1.55a.563.563,0,0,1,.028.172V11.907a.277.277,0,0,0-.05.073,1.893,1.893,0,0,1-2,1.555q-8.068,0-16.136-.005a1.293,1.293,0,0,1-.789-.326Q3.122,10.529.443,7.841a1.515,1.515,0,0,1,0-2.142Q3.1,3.041,5.755.379A1.287,1.287,0,0,1,6.669,0q6.745.009,13.49,0c.9,0,1.8-.01,2.707,0A1.861,1.861,0,0,1,24.636,1.2,3.184,3.184,0,0,1,24.759,1.55Z" transform="translate(0 0)" fill="#fff"/>
    </g>
    <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.485" cy="1.485" r="1.485" transform="translate(4.266 5.283)" fill="#fff"/>
  </g>
</svg>

                            </span><p className="font-17 m-0">Details</p>

                        </div>
                        <div className="">
                            <table className="table table-borderless text-light mb-0">



                                <tbody>
                                <tr className="no-border">
                                    <td className="font-17">Unit Price</td>
                                    <td className="font-17">Quantity</td>
                                    <td className="font-17"></td>

                                </tr>
                                <tr className="no-border">
                                    <td className="font-14"> <img className="mr-1 wh-30" src={BLogo}/>13 ($264.89)</td>
                                    <td className="font-14">1</td>
                                    <td className="font-14 text-right"> <a href="" className="btn btn-primary light font-weight-light p-2 px-3 radius-6 font-16">Buy Now</a></td>

                                </tr>
                                <tr className="no-border">
                                    <td className="font-14"> <img className="mr-1 wh-30" src={BLogo}/>13 ($264.89)</td>
                                    <td className="font-14">1</td>
                                    <td className="font-14 text-right"> <a href="" className="btn btn-primary light font-weight-light p-2 px-3 radius-6 font-16">Buy Now</a></td>

                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="stormaLight radius-15 p-4 mb-3">
                        <div className="d-flex align-items-center">
                            <span className="mr-2">
                              <svg xmlns="http://www.w3.org/2000/svg" width="27.933" height="22.658" viewBox="0 0 27.933 22.658">
  <g id="Group_602" data-name="Group 602" transform="translate(27.933 22.658) rotate(180)">
    <path id="Path_63" data-name="Path 63" d="M0,2.807V0H21.064V2.807Z" transform="translate(6.869 0.815)" fill="#fff"/>
    <path id="Path_64" data-name="Path 64" d="M20.971,2.819H0V0H20.971Z" transform="translate(6.931 9.938)" fill="#fff"/>
    <path id="Path_65" data-name="Path 65" d="M21.064,0V2.807H0V0Z" transform="translate(6.865 19.042)" fill="#fff"/>
    <path id="Path_66" data-name="Path 66" d="M4.512,2.285c-.218,1.365-.96,2.247-2.377,2.174A2.075,2.075,0,0,1,.006,2.087,2.062,2.062,0,0,1,2.237,0C3.658.014,4.36.907,4.512,2.285Z" transform="translate(0)" fill="#fff"/>
    <path id="Path_67" data-name="Path 67" d="M4.508,2.15c-.132,1.384-.829,2.3-2.246,2.312A2.072,2.072,0,0,1,0,2.22,2.061,2.061,0,0,1,2.1,0C3.521-.073,4.272.791,4.508,2.15Z" transform="translate(0.003 9.097)" fill="#fff"/>
    <path id="Path_68" data-name="Path 68" d="M4.507,2.15c-.14,1.375-.835,2.294-2.246,2.309A2.076,2.076,0,0,1,0,2.217,2.06,2.06,0,0,1,2.1,0C3.517-.073,4.266.794,4.507,2.15Z" transform="translate(0.005 18.198)" fill="#fff"/>
  </g>
</svg>

                            </span><p className="font-17 m-0">Current Price</p>
                            <div className="ml-auto">
                                <a href="" className="btn btn-primary light font-weight-light p-2 px-3 radius-6 font-16">Make an Offer</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </div>
    )
}


export default ProductLoki;
