import React from 'react';

import { Container, Row} from "react-bootstrap";

const Footer = () => {

    return (

        <div className="pb-5">
            <div className="text-center">© 2022 Storma. All  sss rights reserved.</div>
        </div>
        

    )
}

export default Footer;